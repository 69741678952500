import Device from "@/pure/Device"

import { Scene, WebGLRenderer, Color, PerspectiveCamera, Group, Vector2 } from "three"

import { getFovHeigth } from "@trinketmage/sword"

import { Pane } from "tweakpane"

class Common {
  scene = new Scene()
  viewport = new Vector2()
  views = [new Group(), new Group()]
  debug = null
  params = {
    sceneColor: "#10100f",
  }

  constructor() {
    this.scene.background = new Color(this.params.sceneColor)

    this.views.forEach((page) => {
      this.scene.add(page)
    })

    this.camera = new PerspectiveCamera(52, Device.viewport.width / Device.viewport.height, 1, 1000.0)
    this.camera.position.z = 50
  }

  init(canvas) {
    this.renderer = new WebGLRenderer({
      canvas: canvas,
      depth: true,
      powerPreference: "high-performance",
      antialias: false,
    })

    this.renderer.autoClear = false

    this.renderer.setPixelRatio(Device.pixelRatio)
    this.debug = window.location.hash === "#debug" ? new Pane() : null
  }

  render(t) {
    t
  }

  dispose() {
    this.renderer.dispose()
  }

  resize() {
    const { viewport, camera } = this
    const { offsetWidth, offsetHeight } = this.renderer.domElement.parentElement
    Device.viewport.set(offsetWidth, offsetHeight)

    document.documentElement.style.setProperty("--vh", offsetHeight * 0.01 + "px")

    camera.aspect = Device.viewport.width / Device.viewport.height
    camera.updateProjectionMatrix()

    this.renderer.setSize(Device.viewport.width, Device.viewport.height)

    const h = getFovHeigth(camera, 50)
    const w = h * camera.aspect
    viewport.set(w, h)
  }

  setDebug() {
    const { debug: pane, params, scene } = this

    params.sceneColor = "#10100f"
    scene.background = new Color(params.sceneColor)

    this.debugFolder = pane.addFolder({
      title: "Common",
      expanded: true,
    })
    this.debugFolder
      .addBinding(params, "sceneColor", {
        label: "color",
        view: "color",
      })
      .on("change", () => {
        scene.background = new Color(params.sceneColor)
      })
  }
}

export default new Common()
