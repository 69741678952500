<template>
  <div class="gabarit" ref="view">
    <div class="view center">
      <div class="main-title">
        <div class="title-col">
          <h1 class="h1-primary line">À PROPOS</h1>

          <h1 class="h1-secondary line">DE L’ATELIER</h1>
        </div>

        <small class="subtitle">
          <div class="small-line">UNE ÉQUIPE SOUDÉE PAR UNE</div>
          <div class="small-line">PASSION COMMUNE : L'UNIVERS DU WEB.</div>
        </small>
      </div>

      <ScrollDownTicker />
    </div>

    <div class="section editorial mobius">
      <div class="block">
        <h4>Artisans de l'innovation et du design web</h4>
        <p>
          Notre passion est de donner vie à des idées numériques. Grâce à notre expertise web, nous sommes des
          artisans de la conception de produits digitaux innovants. Notre approche allie le développement web
          de haute qualité et le design créatif pour créer des expériences qui captivent et inspirent.
        </p>
      </div>
      <div class="block">
        <h4>Forger l'avenir du web sur mesure</h4>
        <p>
          L'agilité comme pilier de notre méthodologie : nous sommes flexibles, réactifs et prêts à relever
          tous les défis pour réaliser vos ambitions numériques. Notre équipe de développeurs, de designers et
          de product owners travaille en harmonie pour créer des solutions web sur mesure.
        </p>
      </div>
    </div>
    <div class="section editorial">
      <h2><span class="before"></span>L’Équipe</h2>
      <div class="row row-1">
        <div class="image-holder"><img data-speed="12.5" src="/assets/about/1.jpg" /></div>
        <div class="block">
          <h4>Une communauté unique alliant professionnalisme et convivialité</h4>
          <p>
            Nous avons à coeur de développer une véritable communauté au sein de L’Atelier, où chacun pourra
            s’épanouir dans un environnement stimulant. Lightning talks, team building, live coding... autant
            de temps forts et de pratiques qui nous permettent d’évoluer sur des sujets variés.
          </p>
          <a
            class="contact-button"
            href="https://www.welcometothejungle.com/fr/companies/l-atelier"
            target="_BLANK">
            <CallToAction label="Nous rejoindre" />
          </a>
        </div>
      </div>
      <div class="row row-2">
        <div class="image-holder"><img data-speed="-12.5" src="/assets/about/2.jpg" /></div>
        <div class="block">
          <h4>Partage, apprentissage et innovation au cœur de notre équipe</h4>
          <p>
            Nos TechDays sont des moments privilégiés pendant lesquels des artisans du web partagent leurs
            expériences et dernières découvertes tech. Ces rendez-vous réguliers contribuent à entretenir nos
            connaissances afin d’accompagner et de conseiller au mieux nos clients.
          </p>
        </div>
      </div>
      <div class="row row-3">
        <div class="image-holder"><img data-speed="-12.5" src="/assets/about/3.jpg" /></div>
        <div class="image-holder"><img data-speed="12.5" src="/assets/about/4.jpg" /></div>
      </div>
      <!-- <div class="row row-4">
        <div class="block">
          <h4>Projets passionnants et aventures en équipe</h4>
          <p>Les séminaires sont l'occasion de se réunir pour discuter de nos projets passionnants et de renforcer nos liens en équipe à travers des activités insolites (comme grimper sur la mer de glace à Chamonix).</p>
        </div>
      </div> -->
      <div class="row row-5">
        <div class="image-holder"><img data-speed="0" src="/assets/about/5.jpg" /></div>
      </div>
      <div class="row row-6">
        <div class="block">
          <h4>Un atelier au coeur de Paris</h4>
          <p>
            Travailler chez L'Atelier, c'est vivre la quintessence de la vie professionnelle parisienne, tout
            en profitant d'un environnement chaleureux, dynamique et axé sur l'apprentissage continu.
            Rejoignez-nous pour faire partie de cette aventure palpitante au cœur de la capitale !
          </p>
        </div>
        <div class="image-holder"><img src="/assets/about/6.jpg" /></div>
      </div>
    </div>
    <div class="section">
      <div class="editorial heading">
        <h2><span class="before"></span>Nos clients</h2>
        <h6>
          Nos clients nous ont fait confiance, et c'est un plaisir de contribuer à la réalisation de leurs
          projets digitaux...
        </h6>
      </div>
      <div class="row clients">
        <div v-for="(client, i) in sortedClients" :key="client.name" class="client array-behave" :idx="i">
          <img :src="client.logo" />
        </div>
      </div>
    </div>
    <div class="section">
      <div class="editorial heading reverse">
        <h2><span class="before"></span>Conseil</h2>
        <h6>
          Vous avez Besoin de renforcer vos équipes avec des experts en développement ? Visitez notre site
          dédié au conseil.
        </h6>
      </div>
      <div class="block">
        <div class="editorial diptyque">
          <div>
            <h3>Artisanat et innovation chez L'Atelier</h3>
            <p>
              L'Atelier conseil, fondé en 2016, représente l'excellence de l'artisanat du web. Notre équipe,
              passionnée de développement, se consacre à des projets web innovants et ambitieux.
            </p>
          </div>
          <div>
            <h3>Services sur-mesure pour votre réussite numérique</h3>
            <p>
              Nous proposons une diversité de services, du conseil à l'expertise technique. Experts sur une
              multitude de technologies, nous personnalisons nos solutions selon vos besoins.
            </p>
          </div>
        </div>
        <a class="contact-button" href="https://conseil.latelier.co" target="_BLANK">
          <CallToAction label="Visiter le site conseil" />
        </a>
      </div>
    </div>
    <FooterSection />
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue"
import CallToAction from "@/components/CallToAction"
import FooterSection from "@/components/FooterSection.vue"
import ScrollDownTicker from "@/components/ScrollDownTicker.vue"

import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

import { useEditorialAnimations } from "@/composables/editorialAnimations"
import clients from "@/locales/clients.js"

export default {
  name: "ViewAbout",
  components: {
    CallToAction,
    FooterSection,
    ScrollDownTicker,
  },
  computed: {
    sortedClients() {
      return clients.slice().sort(() => 0.5 - Math.random())
    },
  },
  setup() {
    let sts = []
    const view = ref(null)
    useEditorialAnimations(view)

    onMounted(() => {
      var x = window.matchMedia("(min-width: 812px)")
      if (x.matches) {
        const $blocks = document.body.querySelectorAll(".image-holder")
        $blocks.forEach((item) => {
          const img = item.querySelector("img")
          const shift = img.dataset.speed ? parseFloat(img.dataset.speed) : 50
          const animation = gsap.timeline()
          animation.fromTo(
            img,
            {
              y: `${shift}%`,
              ease: "linear",
            },
            {
              y: `${-shift}%`,
              ease: "linear",
              overwrite: true,
            }
          )
          sts.push(
            ScrollTrigger.create({
              trigger: img,
              start: "top bottom",
              end: "bottom top",
              scrub: true,
              animation,
            })
          )
        })
      }
    })
    onUnmounted(() => {
      sts.forEach((st) => {
        st.kill(false)
      })
    })

    return {
      view,
    }
  },
}
</script>

<style lang="scss" scoped>
.center {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;

  small {
    max-width: 19em;
  }

  @include md-only {
    padding: var(--gutter-md);
    text-align: left;
  }
  .main-title {
    display: flex;
    align-items: center;
    gap: 0.5em;

    .title-col {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.1em;
    }

    small {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5em;

      .small-line {
        padding: 0%;
      }
    }
  }

  .section.sub {
    padding-top: 0;
  }
}
.mobius {
  @include md {
    display: flex;
    justify-content: center;
    gap: var(--gutter-md);
  }
}

h4 {
  max-width: 15em;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: var(--gutter-md);
  margin-bottom: var(--gutter-md);
  gap: var(--gutter-xs);

  @include md-only {
    flex-direction: column;
  }
  .image-holder {
    position: relative;
    max-width: 100%;
  }
  img {
    display: block;
    border-radius: 2px;
    overflow: hidden;
    max-width: 100%;
  }
}
.block {
  & > a {
    margin-top: 24px;
    display: inline-block;
  }
}
.row-1 {
  .image-holder {
    margin-left: auto;
    margin-right: auto;
  }
  img {
    width: 330px;
    @include md {
      width: 28.125vw;
      height: 41.8198757764vw;
    }
  }
}
.row-2 {
  @include md {
    flex-direction: row-reverse;
  }
  img {
    width: 100%;
    @include md {
      width: 42.3611111111vw;
      height: 22.2800925926vw;
    }
  }
}
.row-3 {
  justify-content: center;
  align-items: flex-start;
  gap: 10px;

  margin-top: 18vw;
  margin-bottom: 18vw;
  flex-direction: row;
  img {
    width: 100%;
    @include md {
      width: 28.009259259259vw;
    }
  }
}
.row-4 {
  flex-direction: column;
  .block {
    @include md {
      display: flex;
      gap: 48px;
    }
    h4 {
      max-width: 10.5em;
      margin-top: 0;
    }
    p {
      margin-top: 0;
    }
  }
}
.row-6 {
  .block {
    margin-left: auto;
    margin-right: auto;
  }
  img {
    width: 330px;
    @include md {
      width: 28.125vw;
      height: 41.8198757764vw;
    }
  }
}
.row-7 {
  @include md {
    padding-right: 28.125vw;
  }
  .image-holder {
    margin-left: auto;
    margin-right: auto;
  }
  img {
    width: 100%;
    @include md {
      width: 35.7638888889vw;
      height: 53.2407407394vw;
    }
  }
}
.clients {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  width: 100%;

  gap: var(--gutter-xxs);
  flex-direction: row;
  margin: var(--gutter-xs) 0 0 0;

  @include lg {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
}

.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--gutter-md);

  @include md-only {
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: var(--gutter-xs);
  }
}

.client {
  width: calc(6 * 16px);
  height: calc(3 * 16px);

  @include md {
    width: calc(8 * 16px);
    height: calc(4 * 16px);
  }
  margin: auto;
}
.contact-button {
  text-decoration: none;
}
.diptyque.editorial {
  margin-top: var(--gutter-xs);
}
</style>
