import gsap from "gsap"
import Common from "@/graphics/Common"

import Particles from "@/graphics/components/Particles"
import Wave from "@/graphics/components/Wave"
import Renaissance from "@/graphics/components/Renaissance"

import Simulation from "@/graphics/components/Simulation"

import PostProcessing from "@/graphics/postprocessing/index"
import { getFovHeigth } from "@trinketmage/sword"
import Device from "@/pure/Device"
import Projects from "@/views/Projects/data"

export default class Output {
  components = {}
  projects = []
  postProcess = true

  constructor() {
    this.init()
  }

  init() {
    this.components.particles = new Particles()

    this.postprocessing = new PostProcessing()
    this.components.wave = new Wave()

    if (!Device.isMobile) {
      this.components.simulation = new Simulation()
      this.postprocessing.mainPass.material.uniforms.velocity.value =
        this.components.simulation.fbos.vel_0.texture
    }

    this.components.renaissance = new Renaissance({ url: Projects[0].image })
    this.components.nxtOffice = new Renaissance({ url: Projects[1].image })
    this.components.infogreffe = new Renaissance({ url: Projects[2].image })
    this.projects.push(this.components.renaissance, this.components.nxtOffice, this.components.infogreffe)
  }

  bindPage({ projects }) {
    this.projects.forEach((project, idx) => {
      project.bind({ el: projects[idx], view: Common.views[0], reset: true })
    })
    Common.views[0].position.y = 0
    this.currentPage = Common.views[0]
  }

  openProject(idx) {
    const tl = new gsap.timeline()
    this.projects.forEach((project, i) => {
      if (i === idx) {
        tl.add(project.enter(), "a")
      } else {
        tl.add(project.fade(), "a")
      }
    })
    return tl
  }

  unbindPage() {
    this.projects.forEach((project) => {
      project.unbind()
    })
  }

  completeProject(el) {
    const idx = el.dataset.idx
    this.projects[idx].animating = true
    this.projects[idx].bind({ el, view: Common.views[1], reset: false })
    Common.views[1].position.y = 0
    this.currentPage = Common.views[1]
    const tl = gsap.timeline({
      onComplete: () => {
        this.projects[idx].animating = false
      },
    })
    tl.add(this.projects[idx].enter2())
    return tl
  }

  updateView({ y, max }) {
    max
    const h = getFovHeigth(Common.camera, 50)
    if (this.currentPage) {
      this.currentPage.position.y = y * h * max
    }
  }

  render(t) {
    Object.keys(this.components).forEach((_) => {
      this.components[_].render(t)
    })

    Common.renderer.setRenderTarget(null)

    if (!this.postProcess) {
      Common.render()
    } else {
      this.postprocessing.render(t)
    }
  }

  dispose() {
    Object.keys(this.components).forEach((_) => {
      this.components[_].dispose()
    })
    this.postprocessing.dispose()
  }

  resize() {
    Object.keys(this.components).forEach((_) => {
      this.components[_].resize()
    })
    this.postprocessing.resize()
  }

  setDebug() {
    Object.keys(this.components).forEach((_) => {
      if ("setDebug" in this.components[_]) {
        this.components[_].setDebug(Common.debug)
      }
    })
  }
}
