import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home/index.vue";
import About from "../views/About/index.vue";
import Project from "../views/Projects/Project.vue";
import AltareaPartenaires from "../views/Projects/AltareaPartenaires.vue";
import InfogreffeMarketplace from "../views/Projects/InfogreffeMarketplace.vue";
import JllNxtoffice from "../views/Projects/JllNxtoffice.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/a-propos",
    name: "About",
    component: About,
  },
  {
    path: "/projet",
    name: "Project",
    component: Project,
    children: [
      {
        path: "altarea-partenaires",
        name: "AltareaPartenaires",
        component: AltareaPartenaires,
        meta: { project: true },
      },
      {
        path: "infogreffe-marketplace",
        name: "InfogreffeMarketplace",
        component: InfogreffeMarketplace,
        meta: { project: true },
      },
      {
        path: "jll-nxtoffice",
        name: "JllNxtoffice",
        component: JllNxtoffice,
        meta: { project: true },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return undefined;
  },
});

export default router;
