import {
  MeshBasicMaterial,
  TextureLoader,
  Clock,
  WebGLRenderTarget,
  Mesh,
  PlaneGeometry,
  Vector2,
  Vector3,
  ShaderMaterial,
  Scene,
  Color,
  SRGBColorSpace,
} from "three"

import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js"

import Common from "@/graphics/Common"
import Device from "@/pure/Device"

import vertexShader from "./shaders/vertex.glsl"
import fragmentShader from "./shaders/fragment.glsl"

import gsap from "gsap"

export default class {
  clock = new Clock()
  offsetCamera = new Vector2(0, 0)
  scrollOffset = 1.2593638896942139
  animating = false
  tl = null
  domSize = new Vector3(0, 0, 1)
  domPos = new Vector2(1, 0)
  params = {
    blurFactor: 1,
    sizeTerm: 1,
  }
  constructor({ url }) {
    this.init()
    const textureLoader = new TextureLoader()

    const bakedTexture = textureLoader.load("/assets/baked.jpg")
    bakedTexture.flipY = false

    const bakedMaterial = new MeshBasicMaterial({
      map: bakedTexture,
    })
    const loader = new GLTFLoader().setPath("/assets/")
    loader.load("scene.glb", (gltf) => {
      const cover = textureLoader.load(url, (texture) => {
        this.leftPortal.material.uniforms.mapAspect.value = texture.image.width / texture.image.height
      })
      cover.colorSpace = SRGBColorSpace
      gltf.scene.traverse((child) => {
        child.material = bakedMaterial
      })
      this.instance = new Scene()
      this.instance.add(...gltf.scene.children)
      this.instance.background = new Color(0xfffff0)
      this.portalCamera = gltf.cameras[0]

      const { pixelRatio, viewport } = Device
      const { width, height } = viewport
      width
      height

      const planeGeo = new PlaneGeometry(1, 1)
      this.leftPortalTexture = new WebGLRenderTarget(width * pixelRatio, (width / 2) * pixelRatio)

      const material = new ShaderMaterial({
        uniforms: {
          ratio: {
            value: 1,
          },
          map: {
            value: cover,
          },
          mapAspect: {
            value: 1,
          },
          blocRes: {
            value: new Vector2(1, 1),
          },
          t: {
            value: 0,
          },
          blurFactor: {
            value: this.params.blurFactor,
          },
          br: {
            value: 0.05,
          },
          pointer: {
            value: new Vector2(10, 10),
          },
          resolution: {
            value: new Vector2(Device.viewport.width, Device.viewport.height),
          },
          alpha: {
            value: 0.5,
          },
        },
        vertexShader,
        fragmentShader,
        transparent: true,
        visible: false,
      })
      this.leftPortal = new Mesh(planeGeo, material)
      this.leftPortal.renderOrder = 999
      this.leftPortal.material.depthTest = false
      this.leftPortal.position.y = -100

      this.resize()
    })
  }
  bind({ el, view, reset }) {
    if (el) {
      this.projectDom = el
      this.params.sizeTerm = 0
      this.params.blurFactor = 1
      this.leftPortal.material.uniforms.blurFactor.value = 1
      if (reset) {
        this.leftPortal.material.uniforms.br.value = 0.02
      } else {
        this.leftPortal.material.uniforms.br.value = 0
      }
      this.updatePosition({ y: 0 })
    } else {
      this.params.sizeTerm = 1
    }
    this.scene = view

    this.resize()
    this.scene.add(this.leftPortal)
    this.leftPortal.material.uniforms.alpha.value = 1
    this.leftPortal.material.visible = true
  }
  unbind() {
    if (this.scene) this.scene.remove(this.leftPortal)
    this.leftPortal.material.visible = false
  }
  fade() {
    if (this.tl) this.tl.kill()
    this.tl = new gsap.timeline()

    const params = {
      alpha: this.leftPortal.material.uniforms.alpha.value,
    }

    if (this.leftPortal) {
      this.tl.to(params, {
        alpha: 0,
        onUpdate: () => {
          this.leftPortal.material.uniforms.alpha.value = params.alpha
        },
      })
    }

    return this.tl
  }
  updatePosition({ y }) {
    if (this.instance) {
      y
      this.scrollOffset = 1.2593638896942139 - parseFloat(y) * 10.0
    }
  }
  enter() {
    if (this.tl) this.tl.kill()
    this.tl = new gsap.timeline()

    const params = {
      aspect: this.portalCamera.aspect,
      width: this.leftPortal.scale.x,
      height: this.leftPortal.scale.y,
      blurFactor: this.params.blurFactor,
      borderRadius: 0.05,
      sizeTerm: 0,
    }
    const { aspect } = Common.camera

    if (this.leftPortal) {
      const h = Math.ceil(Common.viewport.y)
      const w = Math.ceil(Common.viewport.x)
      this.tl.to(params, {
        aspect,
        width: w,
        height: h,
        blurFactor: 0,
        borderRadius: 0,
        ease: "circ.inOut",
        sizeTerm: 1,
        duration: 1,
        onUpdate: () => {
          this.params.sizeTerm = params.sizeTerm

          this.leftPortal.material.uniforms.br.value = params.borderRadius

          this.params.blurFactor = params.blurFactor
          this.leftPortal.material.uniforms.blurFactor.value = params.blurFactor
          this.leftPortal.scale.x = params.width
          this.leftPortal.scale.y = params.height
          this.leftPortal.material.uniforms.blocRes.value.set(params.width, params.height)

          this.computeCamera()
        },
        onComplete: () => {
          this.leftPortal.position.y = 0
        },
      })
    }

    return this.tl
  }
  enter2() {
    if (this.tl) this.tl.kill()
    this.tl = new gsap.timeline()

    const params = {
      aspect: this.portalCamera.aspect,
      width: this.leftPortal.scale.x,
      height: this.leftPortal.scale.y,
      y: this.leftPortal.position.y,
    }
    const { aspect } = Common.camera

    if (this.leftPortal) {
      this.tl.to(params, {
        aspect,
        width: this.domSize.x,
        height: this.domSize.y,
        y: this.domPos.y,
        ease: "circ.out",
        duration: 1.0,
        onUpdate: () => {
          this.leftPortal.scale.x = params.width
          this.leftPortal.scale.y = params.height
          this.leftPortal.position.y = params.y
          this.leftPortal.material.uniforms.blocRes.value.set(params.width, params.height)

          this.computeCamera()
        },
      })
    }

    return this.tl
  }
  init() {}
  render(t) {
    t
  }
  dispose() {
    if (this.tl) this.tl.kill()
    if (this.scene) this.scene.remove(this.leftPortal)
  }
  computeCamera() {
    this.portalCamera.aspect = this.leftPortal.scale.x / this.leftPortal.scale.y
    this.portalCamera.updateProjectionMatrix()
    this.leftPortal.material.uniforms.ratio.value = this.portalCamera.aspect
  }
  resize() {
    const { projectDom, params } = this

    if (!projectDom) {
      return
    }

    if (this.leftPortal) {
      const rect = projectDom.getBoundingClientRect()
      const h = Common.viewport.y
      const w = Common.viewport.x
      if (params.sizeTerm === 0) {
        if (!(this.tl && this.tl.progress() < 1) && !this.animating) {
          this.leftPortal.position.y =
            -h * ((rect.top + document.documentElement.scrollTop) / Device.viewport.height) +
            h / 2 -
            ((rect.height / Device.viewport.height) * h) / 2
          this.leftPortal.scale.set(
            (rect.width / Device.viewport.width) * w,
            (rect.height / Device.viewport.height) * h,
            1.0
          )
          this.leftPortal.material.uniforms.blocRes.value.set(
            (rect.width / Device.viewport.width) * w,
            (rect.height / Device.viewport.height) * h
          )
        }
        this.domPos.set(
          0,
          -h * ((rect.top + document.documentElement.scrollTop) / Device.viewport.height) +
            h / 2 -
            ((rect.height / Device.viewport.height) * h) / 2
        )
        this.domSize.set(
          (rect.width / Device.viewport.width) * w,
          (rect.height / Device.viewport.height) * h,
          1.0
        )
      } else if (params.sizeTerm === 1) {
        this.leftPortal.position.y = 0
        this.leftPortal.scale.set(w, h, 1.0)
        this.leftPortal.material.uniforms.blocRes.value.set(w, h)
      }
    }
    this.computeCamera()
  }
  setDebug(pane) {
    pane
  }
}
