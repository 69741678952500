import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { ref } from 'vue';
import Device from '@/pure/Device';

export default {
  thumbPosition: ref(0),
  progress: 0,
  maxScroll: 0,
  domScrollIndicatorHeight: 0,
  r: 0,
  scrollIndicatorActiveRatio: 0,
  previousScrollViewDelta: 0,
  lastMouseInteractiveTime: -1 / 0,
  scrollViewDelta: 0,
  isIndicatorActive: void 0,
  init() {
    this.domScrollIndicator = document.getElementById("scroll-indicator");
    this.domScrollIndicatorThumb = this.domScrollIndicator.querySelector(".thumb");
    this.boundUpdate = this.update.bind(this);
    this.boundResize = this.resize.bind(this);
    gsap.ticker.add(this.boundUpdate);
    ScrollTrigger.addEventListener('refresh', this.boundResize);
    this.resize();
  },
  refresh() {
    this.progress = 0;
    this.resize();
  },
  update() {
    this.scrollViewDelta = this.progress - this.previousScrollViewDelta;
    Math.abs(this.scrollViewDelta) > 0 ? (this.lastMouseInteractiveTime = gsap.ticker.time * 1000,
      this.isIndicatorActive = !0) : gsap.ticker.time * 1000 > this.lastMouseInteractiveTime + .5 && (this.isIndicatorActive = !1);
      this.scrollIndicatorActiveRatio = gsap.utils.clamp(0, 1, this.scrollIndicatorActiveRatio + (this.isIndicatorActive ? 2 : -2) * 0.0075),
      this.domScrollIndicator.style.opacity = this.scrollIndicatorActiveRatio;
    this.domScrollIndicatorThumb.style.transform = `translate3d(0, ${ this.domScrollIndicatorHeight * this.progress * (1 - this.r) }px,0)`;
    this.previousScrollViewDelta = this.progress;
  },
  resize() {
    this.domScrollIndicatorHeight = this.domScrollIndicator.getBoundingClientRect().height;
    this.r = Device.viewport.height / this.maxScroll;
    this.domScrollIndicatorThumb.style.height = this.domScrollIndicatorHeight * this.r + "px";
  },
  dispose() {
    gsap.ticker.remove(this.boundUpdate);
    ScrollTrigger.removeEventListener('refresh', this.boundResize);
  }
}
