<template>
  <a @mouseenter="handleHover(true)" @mouseleave="handleHover(false)" class="nav-link">
    <span v-for="n in 2" :key="n" ref="textContainers" class="word">
      {{ label }}
    </span>
  </a>
</template>

<script>
import gsap from "gsap"
import { SplitText } from "gsap/SplitText"
import useDelayElement from "@/composables/useDelayElement"

gsap.registerPlugin(SplitText)

export default {
  name: "CallToAction",
  props: {
    label: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      splits: [],
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.textContainers.forEach((container) => {
        const splitInstance = new SplitText(container, {
          type: "chars, lines",
          linesClass: "lineClass",
          charsClass: "charClass",
        })
        this.splits.push(splitInstance)
      })
    })
  },

  methods: {
    handleHover(isHovered) {
      this.splits.forEach((split) => {
        split.chars.forEach((char, i) => {
          gsap.to(char, {
            ease: "power4.out",
            transform: isHovered ? "translate3d(0, -200%, 0)" : "translate3d(0, 0%, 0)",
            duration: useDelayElement(0.6, 0.05, i, split.chars.length - 1, 1),
          })
        })
      })
    },
  },
}
</script>

<style lang="scss">
.nav-link {
  position: relative;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: var(--primary-200);
  padding: 0.5em 0em 0.5em;

  font-variation-settings: "wght" 500;
  letter-spacing: 0.4px;

  .word {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &:nth-child(2) {
      position: absolute;
      transform: translate3d(0, 200%, 0);
    }
  }

  .lineClass {
    transform: translate3d(0, 0, 0);
  }

  .charClass {
    margin: auto 0;
    line-height: calc(var(--nav-sizing / 2) - var(--stroke) * 4);
  }
}
</style>
