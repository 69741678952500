<template>
  <div class="gabarit project-view">
    <div class="half view" data-idx="2">
      <img src="/assets/infogreffe/tribunaldecommerce.jpeg" class="project-image" />
    </div>

    <div class="main-title">
      <div class="project-title-first">
        <h1 class="line h1-primary">INFOGREFFE</h1>
        <small class="subtitle">
          <div class="small-line">FAVORISER LA RENCONTRE ENTRE</div>
          <div class="small-line">CÉDANTS, REPRENEURS, ASSOCIÉS ET</div>
          <div class="small-line">INVESTISSEURS.</div>
        </small>
      </div>

      <h1 class="line h1-secondary">MARKETPLACE</h1>
    </div>
    <div class="specifications">
      <div class="specification">
        <h3>GESTION DE PROJET</h3>
        <span>1 Product Owner, 1 UX/UI, 3 développeurs</span>
        <span>3 sprints de 2 semaines</span>
      </div>
      <div class="specification">
        <h3>STACK</h3>
        <span>VueJS / .Net Core / Azure</span>
      </div>
      <div class="specification">
        <h3>FORMAT</h3>
        <span>Site web</span>
        <span>Responsive Mobile</span>
      </div>
    </div>
    <div class="description">
      Détenteur de nombreuses données sur les entreprises en France, Infogreffe dispose d’une forte légitimité
      pour adresser la problématique de cession ou reprise d’entreprises.
    </div>
    <div class="separator-line"></div>
    <div class="marquee">
      <div class="marquee__inner" aria-hidden="true">
        <span>MARKETPLACE</span>
        <span>MARKETPLACE</span>
        <span>MARKETPLACE</span>
        <span>MARKETPLACE</span>
      </div>
    </div>
    <div class="marquee reverse">
      <div class="marquee__inner" aria-hidden="true">
        <span>MARKETPLACE</span>
        <span>MARKETPLACE</span>
        <span>MARKETPLACE</span>
        <span>MARKETPLACE</span>
      </div>
    </div>
    <div class="fullscreen" ref="fullscreen">
      <img src="/assets/infogreffe/main.jpg" />
    </div>
    <div class="context">
      <h3>Contexte</h3>
      <p>
        Infogreffe est un groupement d'intérêt économique, éditant depuis 1986 le service de diffusion de
        l'information légale et officielle sur les entreprises, notamment le Registre du commerce et des
        sociétés (RCS), à travers plusieurs canaux commerciaux, notamment un site web et des services
        associés. Infogreffe désirait adresser ce secteur en concevant une Marketplace simple et intuitive.
      </p>
    </div>
    <div class="dyptique">
      <div class="image-helper">
        <img src="/assets/infogreffe/dip1.jpg" />
      </div>
      <div class="image-helper">
        <img src="/assets/infogreffe/dip2.jpg" />
      </div>
    </div>
    <div class="context">
      <h3>Problématique</h3>
      <p>
        Avant la mise sur le marché de cette plateforme, aucun autre service existant ne proposait de mettre
        directement en relation cédants, repreneurs, associés et investisseurs.<br />Nous avons, en
        co-création avec Infogreffe, pensé et conçu une interface intuitive ainsi que l’entièreté de
        l’infrastructure sous-jacente permettant le bon fonctionnement de la plateforme malgré les nombreuses
        données sur lesquelles repose cette dernière.
      </p>
    </div>
    <div class="max-width">
      <img src="/assets/infogreffe/last.jpg" />
    </div>
    <NextProject name="AltareaPartenaires" />
    <FooterSection />
  </div>
</template>

<script>
import { inject, onMounted, onUnmounted, ref } from "vue"
import NextProject from "./NextProject.vue"
import FooterSection from "@/components/FooterSection.vue"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import projects from "@/views/Projects/data"
export default {
  name: "ViewProject",
  components: { NextProject, FooterSection },
  setup() {
    const graphics = inject("Graphics")
    const fullscreen = ref(null)

    onMounted(() => {
      ScrollTrigger.create({
        trigger: fullscreen.value,
        start: "top bottom",
        end: "bottom top",
        scrub: true,
        animation: gsap.fromTo(
          fullscreen.value.querySelector("img"),
          { yPercent: -25 },
          { yPercent: 25, ease: "none" }
        ),
      })
    })
    onUnmounted(() => {
      graphics.output.unbindPage()
    })
    return {
      fullscreen,
      projects,
    }
  },
}
</script>

<style lang="scss" scoped></style>
