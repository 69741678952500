import * as THREE from "three"
import { GPUComputationRenderer } from "three/addons/misc/GPUComputationRenderer.js"
import simulationVelocity from "./shaders/simulation-velocity.frag"
import simulationPosition from "./shaders/simulation-position.frag"
import { getRandomSpherePoint } from "./../../utils/geometry"

import Input from "@/graphics/Input"
import Device from "@/pure/Device"

export default class FBO {
  colors = [new THREE.Color(0xffffff)]

  constructor(width, height, renderer, scene, renderMaterial, dataTexture, params) {
    this.width = width
    this.height = height
    this.renderer = renderer
    this.scene = scene
    this.renderMaterial = renderMaterial
    this.dataTexture = dataTexture
    this.params = params

    this.gl = this.renderer.getContext()

    this.init()
  }

  init() {
    this.simSetup()
    this.createParticles()
  }

  simSetup() {
    const fillTexture = (texture, callback) => {
      var array = texture.image.data

      for (var k = 0, kl = array.length; k < kl; k += 4) {
        callback(array, k)
      }
    }

    const gpuCompute = new GPUComputationRenderer(this.width, this.width, this.renderer)

    if (this.renderer.capabilities.isWebGL2 === false) {
      gpuCompute.setDataType(THREE.HalfFloatType)
    }

    const orgPositionTexture = this.dataTexture
    const positionTexture = gpuCompute.createTexture()
    const velocityTexture = gpuCompute.createTexture()

    fillTexture(positionTexture, (arr, i) => {
      const point = getRandomSpherePoint()
      arr[i + 0] = point.x
      arr[i + 1] = point.y
      arr[i + 2] = point.z
      arr[i + 3] = 0.0
    })

    fillTexture(positionTexture, (arr, i) => {
      arr[i + 0] = orgPositionTexture.image.data[i + 0]
      arr[i + 1] = orgPositionTexture.image.data[i + 1]
      arr[i + 2] = orgPositionTexture.image.data[i + 2]
      arr[i + 3] = orgPositionTexture.image.data[i + 3]
    })

    fillTexture(velocityTexture, (arr, i) => {
      arr[i + 0] = 0
      arr[i + 1] = 0
      arr[i + 2] = 0
      arr[i + 3] = 0
    })

    const positionVar = gpuCompute.addVariable("texturePositions", simulationPosition, positionTexture)

    const velocityVar = gpuCompute.addVariable("textureVelocities", simulationVelocity, velocityTexture)

    const perlin = new THREE.TextureLoader().load("/assets/PerlinNoise.png")
    velocityVar.material.uniforms.t = { value: this.params.timeOffset * 0.001 }
    velocityVar.material.uniforms.morphTerm = { value: 1.0 }
    velocityVar.material.uniforms.perlinOnMorphTerm = { value: 0.5 }
    velocityVar.material.uniforms.forces = { value: this.params.forces }
    velocityVar.material.uniforms.predator = { value: new THREE.Vector3(Input.coords.x, Input.coords.y, 0) }
    velocityVar.material.uniforms.orgPositions = { value: orgPositionTexture }
    velocityVar.material.uniforms.ratio = { value: window.innerWidth / window.innerHeight }
    positionVar.material.uniforms.orgPositions = { value: orgPositionTexture }
    velocityVar.material.uniforms.perlin = { value: perlin }
    velocityVar.material.uniforms.rapport = { value: 1 }

    gpuCompute.setVariableDependencies(velocityVar, [velocityVar, positionVar])
    gpuCompute.setVariableDependencies(positionVar, [velocityVar, positionVar])

    const error = gpuCompute.init()
    if (error !== null) {
      // console.error(error);
    }

    this.gpuCompute = gpuCompute
    this.positionVariable = positionVar
    this.velocityVariable = velocityVar
  }

  createParticles() {
    const length = this.width * this.height
    let vertices = new Float32Array(length * 3)
    let colors = new Float32Array(length * 3)
    for (let i = 0; i < length; i++) {
      const color = this.colors[THREE.MathUtils.randInt(0, this.colors.length - 1)]

      let i3 = i * 3
      vertices[i3 + 0] = (i % this.width) / this.width
      vertices[i3 + 1] = i / this.width / this.height

      let x = 0

      colors[i3] = color.r + x
      colors[i3 + 1] = color.g + x
      colors[i3 + 2] = color.b + x
    }

    const geometry = new THREE.BufferGeometry()
    geometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3))
    geometry.setAttribute("color", new THREE.BufferAttribute(colors, 3))
    this.particles = new THREE.Points(geometry, this.renderMaterial)
    this.particles.frustumCulled = false
  }

  update(time) {
    time

    this.gpuCompute.compute()
    const texture = this.gpuCompute.getCurrentRenderTarget(this.positionVariable).texture
    this.particles.material.uniforms.positions.value = texture
    this.velocityVariable.material.uniforms.predator = {
      value: new THREE.Vector3(
        Input.coords.x * (Device.viewport.width / Device.viewport.height),
        Input.coords.y,
        0
      ),
    }

    if (this.debugMaterial) {
      this.debugMaterial.map = texture
      this.debugMaterial.needsUpdate = true
    }
  }

  dispose() {
    this.gpuCompute.dispose()
    this.particles.geometry.dispose()
    this.particles.material.dispose()
  }

  setDebug() {
    const { scene } = this
    const geometry = new THREE.PlaneGeometry(1, 1, 1)
    this.debugMaterial = new THREE.MeshBasicMaterial({
      visible: false,
      color: 0xffffff,
    })
    this.plane = new THREE.Mesh(geometry, this.debugMaterial)
    scene.add(this.plane)
  }
}
