<template>
  <div class="next-project">
    <RouterLink class="project-helper interactable" :to="{ name: project.name }">
      <div class="main-title">
        <div>
          <h1 class="line h1-secondary">{{ project.client }}</h1>
          <h1 class="line h1-primary">{{ project.project }}</h1>
          <CallToAction label="Découvrir" />
        </div>
      </div>
    </RouterLink>
  </div>
</template>

<script>
import { ref } from "vue"
import projects from "@/views/Projects/data"
import CallToAction from "@/components/CallToAction"
export default {
  name: "NextProject",
  components: { CallToAction },
  props: {
    name: String,
  },
  setup(props) {
    const project = ref(projects.find((item) => item.name === props.name))
    return {
      project,
    }
  },
}
</script>

<style lang="scss" scoped>
.next-project {
  padding: var(--gutter-md) 0;
  margin: 0 var(--gutter-md);
  display: flex;
  align-items: center;
  border-top: 2px solid var(--primary-800);
  @include md {
    justify-content: center;
  }
}
.main-title {
  @include md-only {
    text-align: left;
    b.line {
      display: inline-block;
    }
  }
}
a {
  text-decoration: none;
  color: inherit;
}
.call-to-action {
  @include md-only {
    margin-top: var(--gutter-xs);
  }
  @include md {
    margin-left: auto;
  }
}
</style>
