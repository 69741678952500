<template>
  <div class="gabarit project-view">
    <div class="half view" data-idx="0">
      <img src="/assets/renaissance/villagesdelarche.jpeg" class="project-image" />
    </div>
    <div class="main-title">
      <div class="project-title-first">
        <h1 class="line h1-primary">ALTAREA</h1>
        <small class="subtitle">
          <div class="small-line">CONCEVOIR UNE APP QUI PERMET</div>
          <div class="small-line">AUX UTILISATEURS DE MIEUX VENDRE</div>
          <div class="small-line">LES BIENS IMMOBILIERS.</div>
        </small>
      </div>

      <h1 class="line h1-secondary">APP PARTENAIRES</h1>
    </div>

    <div class="specifications">
      <div class="specification">
        <h3>GESTION DE PROJET</h3>
        <span>1 Product Owner, 2 UX/UI, 5 développeurs</span>
        <span>5 sprints de 2 semaines</span>
      </div>
      <div class="specification">
        <h3>STACK</h3>
        <span>VueJS / .Net Core / Capacitor / Azure</span>
      </div>
      <div class="specification">
        <h3>FORMAT</h3>
        <span>Application Mobile / Tablette</span>
        <span>Responsive desktop</span>
      </div>
    </div>
    <div class="description">
      L’application mobile Altarea Partenaires est conçue pour révolutionner l'expérience des conseillers en
      gestion de patrimoine, en leur offrant un outil puissant pour optimiser la vente des biens immobiliers,
      alliant information détaillée et accessibilité inégalée.
    </div>
    <div class="separator-line"></div>
    <div class="marquee">
      <div class="marquee__inner" aria-hidden="true">
        <span>Partenaires</span>
        <span>Partenaires</span>
        <span>Partenaires</span>
        <span>Partenaires</span>
      </div>
    </div>
    <div class="marquee reverse">
      <div class="marquee__inner" aria-hidden="true">
        <span>Partenaires</span>
        <span>Partenaires</span>
        <span>Partenaires</span>
        <span>Partenaires</span>
      </div>
    </div>
    <div class="fullscreen" ref="fullscreen">
      <img src="/assets/renaissance/app-partenaires.jpg" />
    </div>
    <div class="context">
      <h3>Contexte</h3>
      <p>
        Fondé en 1994, Altarea est un groupe immobilier leader de la transformation urbaine en France. Altarea
        développe des projets immobiliers qui incluent toutes les classes d’actifs (résidentiel, commerce,
        équipements publics, hôtels, résidences services, bureaux…) à travers ses différentes marques telles
        que : Cogedim, Pitch Immo, Histoire & Patrimoine, Sévérini et Woodeum.
      </p>
    </div>
    <div class="dyptique">
      <div class="image-helper">
        <img src="/assets/renaissance/V_3.jpg" />
      </div>
      <div class="image-helper">
        <img src="/assets/renaissance/V_4.jpg" />
      </div>
    </div>
    <div class="carousel">
      <div class="slides">
        <div class="slide">
          <img src="/assets/renaissance/phone1.png" />
        </div>
        <div class="slide">
          <img src="/assets/renaissance/phone2.png" />
        </div>
        <div class="slide">
          <img src="/assets/renaissance/phone3.png" />
        </div>
        <div class="slide">
          <img src="/assets/renaissance/phone3.png" />
        </div>
      </div>
    </div>
    <div class="context">
      <h3>Problématique</h3>
      <p>
        Un site Altarea Partenaires existant permettait déjà aux Conseillers en Gestion de Patrimoine dits
        “Partenaires” de gérer l’ensemble de leurs ventes, depuis la recherche d’un logement pour leur client
        jusqu’à la transaction.<br />
        L’objectif qui nous a été confié était de repenser ce portail web pour le transformer en une
        application mobile plus adaptée à la réalité terrain des Partenaires. Cette application devait
        reprendre l’ensemble des fonctionnalités disponibles sur le site web existant tout en dynamisant
        l’activité de prescription et réduisant le temps de chaque action.
      </p>
      <p>Les maîtres mots pour le succès du produit étaient : confort, rapidité, efficacité.</p>
    </div>
    <div class="max-width">
      <img src="/assets/renaissance/V_2.jpg" />
    </div>
    <NextProject name="JllNxtoffice" />
    <FooterSection />
  </div>
</template>

<script>
import { inject, onMounted, onUnmounted, ref } from "vue"
import FooterSection from "@/components/FooterSection.vue"
import NextProject from "./NextProject.vue"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
export default {
  name: "ViewProject",
  components: { NextProject, FooterSection },
  setup() {
    const graphics = inject("Graphics")
    const fullscreen = ref(null)

    onMounted(() => {
      ScrollTrigger.create({
        trigger: fullscreen.value,
        start: "top bottom",
        end: "bottom top",
        scrub: true,
        animation: gsap.fromTo(
          fullscreen.value.querySelector("img"),
          { yPercent: -25 },
          { yPercent: 25, ease: "none" }
        ),
      })
    })
    onUnmounted(() => {
      graphics.output.unbindPage()
    })
    return {
      fullscreen,
    }
  },
}
</script>

<style lang="scss" scoped></style>
