<template>
  <button class="call-to-action">
    <span class="caption">{{ label }}</span>
    <div class="decorator">
      <div class="arrow arrow-1">
        <div class="line"></div>
      </div>
      <div class="arrow arrow-2">
        <div class="line"></div>
      </div>
    </div>
  </button>
</template>

<script>
export default {
  name: "CallToAction",
  props: {
    label: {
      type: String,
      default: "",
    },
  },
}
</script>

<style lang="scss" scoped>
.call-to-action {
  --transistion-ease: cubic-bezier(0.3, 0, 0.3, 1);

  color: var(--primary-300);
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 118%;
  letter-spacing: 0.025em;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  cursor: pointer;
  position: relative;
  padding: 0;
  margin: 0;

  .decorator {
    box-sizing: content-box;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 300%;

    border-radius: 2px;
    background-color: var(--primary-800);

    width: 48px;
    height: 48px;

    overflow: hidden;

    transition: all 0.2s ease 0.01s;

    .arrow {
      position: relative;
      top: 0%;
      left: 0%;
      width: 6px;
      height: 6px;

      border-top: var(--stroke) solid var(--primary-300);
      border-right: var(--stroke) solid var(--primary-300);

      transform: translate3d(-2700%, 0%, 0) rotate(45deg) scale(1);

      transition: transform 0s ease;

      .line {
        content: "";
        position: absolute;
        top: -50%;
        left: -50%;
        width: calc(var(--stroke) * 9);
        height: var(--stroke);
        background-color: var(--primary-300);
        transform: rotate(-45deg) translate3d(-30%, -100%, 0) scaleX(1);
        transform-origin: right center;

        transition: all 0s ease 0s;
      }
    }

    .arrow-1 {
      position: absolute;

      top: 50%;
      width: 6px;
      height: 6px;

      transform: translate3d(-2700%, -50%, 0) rotate(45deg) scale(1);

      .line {
        transform: rotate(-45deg) translate3d(-30%, -100%, 0) scaleX(50);

        background-color: var(--primary-300);
      }
    }

    .arrow-2 {
      transform: translate3d(0%, 0%, 0) rotate(45deg) scale(1);

      .line {
        transform: rotate(-45deg) translate3d(-30%, -100%, 0) scaleX(1);

        background-color: var(--primary-300);
      }
    }
  }
}

.call-to-action:hover {
  .decorator {
    background-color: var(--primary-700);

    .arrow {
      transition: transform 0.45s var(--transistion-ease) 0.01s;
    }
    .arrow-1 {
      transform: translate3d(273%, -46%, 0) rotate(45deg) scale(1);

      .line {
        transform: rotate(-45deg) translate3d(-30%, -100%, 0) scaleX(1);
        transition: all 0.45s var(--transistion-ease) 0.04s;
      }
    }

    .arrow-2 {
      transform: translate3d(5000%, -50%, 0) rotate(45deg) scale(1);
      .line {
        transform: rotate(-45deg) translate3d(-30%, -100%, 0) scaleX(10);
        transition: all 0.45s var(--transistion-ease);
      }
    }
  }
}
</style>
