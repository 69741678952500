<template>
  <a
    @mouseenter="handleHover(true)"
    @mouseleave="handleHover(false)"
    class="contact-nav-button"
    href="mailto:hello@latelier.co"
    target="_BLANK">
    <div class="contact-arrow" ref="arrow">
      <div class="arrow-after" ref="arrowBefore"></div>
    </div>
    <span v-for="n in 2" :key="n" ref="textContainers" class="word">
      {{ label }}
    </span>
  </a>
</template>

<script>
import gsap from "gsap"
import { SplitText } from "gsap/SplitText"
import useDelayElement from "@/composables/useDelayElement"

export default {
  props: {
    label: {
      type: String,
      default: "Nous contacter",
    },
  },
  data() {
    return {
      splits: [],
      isHovered: false,
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.$refs.textContainers.forEach((container) => {
        const splitInstance = new SplitText(container, {
          type: "chars, lines",
          linesClass: "linesClass",
          charsClass: "charClass",
        })
        this.splits.push(splitInstance)
      })
    })
  },
  methods: {
    handleHover(isHovering) {
      this.isHovered = isHovering

      if (isHovering) {
        this.splits.forEach((split) => {
          split.chars.forEach((char, i) => {
            gsap.to(char, {
              ease: "power4.out",
              transform: "translate3d(0, -100%, 0)",
              duration: useDelayElement(0.6, 0.1, i, split.chars.length - 1, 1),
            })
          })
        })
        setTimeout(() => {
          if (this.isHovered) {
            this.animIntro()
          }
        }, 75)
      } else {
        this.splits.forEach((split) => {
          split.chars.forEach((char, i) => {
            gsap.to(char, {
              ease: "power4.out",
              transform: "translate3d(0, 0%, 0)",
              duration: useDelayElement(0.6, 0.05, i, split.chars.length - 1, 1),
            })
          })
        })
        this.animOutro()
      }
    },

    animIntro() {
      const tl = gsap.timeline({
        defaults: {
          duration: 0.4,
          ease: "power4.out",
        },
      })

      this.splits.forEach((split) => {
        tl.to(split.lines, {
          x: "10%",
        })
      })

      tl.to(
        this.$refs.arrow,
        {
          left: "10%",
          transform: "translate3d(0, -50%, 0) rotate(45deg) scale(1)",
        },
        "<"
      )

      tl.to(
        this.$refs.arrow.querySelector(".arrow-after"),
        {
          transform: "rotate(-45deg) translate3d(-30%, -100%, 0) scaleX(1)",
        },
        "< -=.1"
      )
    },

    animOutro() {
      const tl = gsap.timeline({
        defaults: {
          duration: 0.4,
          ease: "power4.out",
        },
      })

      this.splits.forEach((split) => {
        tl.to(split.lines, {
          x: "0%",
        })
      })

      tl.to(
        this.$refs.arrow,
        {
          left: "-10%",
          transform: "translate3d(0, -50%, 0) rotate(45deg) scale(0.5)",
        },
        "<"
      )

      tl.to(
        this.$refs.arrow.querySelector(".arrow-after"),
        {
          transform: "rotate(-45deg) translate3d(-30%, -100%, 0) scaleX(50)",
        },
        "<"
      )
    },
  },
}
</script>

<style lang="scss">
$duration: 0.6s;

.contact-nav-button {
  position: relative;
  overflow: hidden;
  padding: 0 1.75em;

  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  height: var(--nav-sizing);

  color: var(--primary-200);
  border: 3px solid var(--primary-400, #b9b9b9);
  border-radius: 2px;
  font-variation-settings: "wght" 500;
  letter-spacing: 0.4px;

  transition: border $duration cubic-bezier(0.46, 0.52, 0.22, 1);

  &::before {
    transition: calc($duration * 0.8) cubic-bezier(0.46, 0.52, 0.22, 1);
  }

  .contact-arrow {
    position: absolute;
    top: 50%;
    left: -5%;
    width: 6px;
    height: 6px;

    border-top: var(--stroke) solid var(--primary-800);
    border-right: var(--stroke) solid var(--primary-800);

    transform: translate3d(0, -50%, 0) rotate(45deg) scale(0.1);

    .arrow-after {
      content: "";
      position: absolute;
      top: -50%;
      left: -50%;
      width: calc(var(--stroke) * 9);
      height: var(--stroke);
      background-color: var(--primary-800);
      transform: rotate(-45deg) translate3d(-30%, -100%, 0) scaleX(50);
      transform-origin: right center;
    }
  }

  .word {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    &:nth-child(3) {
      position: absolute;
      color: var(--primary-800);
      transform: translate3d(0, 100%, 0);
    }
  }

  .linesClass {
    height: 100%;
    transform: translate3d(0, 0, 0);

    text-align: center;
  }

  .charClass {
    line-height: calc(var(--nav-sizing) - var(--stroke) * 4);
    will-change: transform;
  }

  &::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 110%;
    aspect-ratio: 1 / 1;
    background-color: var(--primary-100);
    border-radius: 100%;
    transform-origin: center center;
    transform: scale(0.1) translate3d(-50%, 0%, 0);
  }

  &:hover {
    border-color: var(--primary-100);

    &::before {
      top: -160%;
      left: 50%;
      transform: translate3d(-50%, 0%, 0) scale(1);
    }
  }
}
</style>
