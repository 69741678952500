import gsap from "gsap"

import {
  ShaderMaterial,
  DataTexture,
  RGBAFormat,
  FloatType,
  Uniform,
  Color,
  MeshBasicMaterial,
  DoubleSide,
  Vector2,
  Vector3,
} from "three"

import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js"
import { MeshSurfaceSampler } from "three/addons/math/MeshSurfaceSampler.js"

import vertexShader from "./shaders/vertex.glsl"
import fragmentShader from "./shaders/fragment.glsl"

import FBO from "./FBO"

import Common from "@/graphics/Common"
import Device from "@/pure/Device"

export default class {
  params = {
    color: "#FFFFF0",
    size: 0.02,
    speed: 1,
    curlFreq: 0.25,
    spherical: 1,
    stable: 0,
    morphTerm: 1,
    perlinOnMorphTerm: 0.5,
    autoplay: true,
    tweenMorph: 1,
    timeOffset: 100,
    uAlpha: 0.2,
    forces: new Vector2(0.4, 0),
  }
  constructor() {
    this.scene = Common.scene
    this.renderer = Common.renderer
    this.init()
  }
  init() {
    this.data = new Float32Array(512 * 512 * 4)
    this.preFabWhite = new MeshBasicMaterial({
      color: 0xffffff,
      side: DoubleSide,
    })

    const loader = new GLTFLoader().setPath("/assets/")
    loader.load("logold.glb", (gltf) => {
      const miskin = gltf.scene.children[0]
      miskin.updateMatrix()

      gltf.scene.traverse((children) => {
        if (children.isMesh) {
          children.material = this.preFabWhite
        }
      })
      this.sampler = new MeshSurfaceSampler(miskin).setWeightAttribute(null).build()

      const vertices = []
      const tempPosition = new Vector3()
      for (let i = 0; i < 512 * 512; i++) {
        this.sampler.sample(tempPosition)
        vertices.push(tempPosition.x, tempPosition.y, tempPosition.z)
        this.data[i * 4] = tempPosition.x
        this.data[i * 4 + 1] = tempPosition.y
        this.data[i * 4 + 2] = 0.0
        this.data[i * 4 + 3] = 1.0
      }

      this.dataTexture = new DataTexture(this.data, 512, 512, RGBAFormat, FloatType)
      this.dataTexture.needsUpdate = true
      this.setFBO()
    })
  }
  setFBO() {
    this.renderMaterial = new ShaderMaterial({
      uniforms: {
        uColor: new Uniform(new Color(this.params.color)),
        positions: { value: null },
        orgPositions: { value: this.dataTexture },
        stable: { value: this.params.stable },
        size: new Uniform(this.params.size),
        uAlpha: new Uniform(this.params.uAlpha),
        timeOffset: new Uniform(this.params.timeOffset),
        depthWrite: false,
        depthTest: true,
      },
      transparent: true,
      vertexShader,
      fragmentShader,
    })

    this.fbo = new FBO(
      512,
      512,
      this.renderer,
      this.scene,
      this.renderMaterial,
      this.dataTexture,
      this.params
    )
    this.scene.add(this.fbo.particles)

    this.fbo.setDebug(this.scene)
  }
  pending() {
    this.tl = new gsap.timeline({
      onUpdate: () => {
        if (this.fbo) {
          this.fbo.velocityVariable.material.uniforms.morphTerm.value = this.params.tweenMorph
        }
      },
      repeat: -1,
    })
    this.tl
      .to(this.params, {
        tweenMorph: 0,
        ease: "linear",
        duration: 1,
        delay: 0.5,
      })
      .to(this.params, {
        tweenMorph: 1,
        ease: "linear",
        duration: 1.5,
        delay: 0.5,
      })
  }
  enter() {
    this.tl = new gsap.timeline({
      onUpdate: () => {
        if (this.fbo) {
          this.fbo.velocityVariable.material.uniforms.morphTerm.value = this.params.tweenMorph
          this.renderMaterial.uniforms.uAlpha.value = this.params.uAlpha
        }
      },
      onComplete: () => {
        this.dispose()
      },
    })
    this.tl
      .to(this.params, {
        tweenMorph: 0,
        ease: "linear",
        duration: 1,
        delay: 0.5,
      })
      .to(this.params, {
        uAlpha: 0,
        ease: "linear",
        duration: 1.5,
        delay: 0,
        onComplete: () => {
          this.fbo.velocityVariable.material.uniforms.rapport.value = -1
        },
      })
    return this.tl
  }
  render(t) {
    if (this.fbo) this.fbo.update(t)
  }
  dispose() {
    this.scene.remove(this.fbo.particles)
    this.fbo.dispose()
  }
  resize() {
    if (this.fbo) {
      const { width, height } = Device.viewport
      this.fbo.velocityVariable.material.uniforms.ratio.value = width / height
    }
  }
  setDebug(pane) {
    const { params, tl } = this
    this.particlesFolder = pane.addFolder({
      title: "Particles",
      expanded: true,
    })

    this.particlesFolder
      .addBinding(params, "size", {
        min: 0.0,
        max: 1,
        step: 0.0001,
      })
      .on("change", () => {
        this.renderMaterial.uniforms.size.value = params.size
      })
    this.particlesFolder
      .addBinding(params, "perlinOnMorphTerm", {
        min: 0.0,
        max: 1,
        label: "XOrPerlin",
      })
      .on("change", () => {
        this.fbo.velocityVariable.material.uniforms.perlinOnMorphTerm.value = params.perlinOnMorphTerm
      })
    this.particlesFolder
      .addBinding(params, "timeOffset", {
        min: 0,
        max: 100,
        label: "time",
      })
      .on("change", () => {
        this.fbo.velocityVariable.material.uniforms.t.value = params.timeOffset * 0.001
      })
    this.particlesFolder
      .addBinding(params, "morphTerm", {
        min: 0.0,
        max: 1,
        step: 0.0001,
      })
      .on("change", () => {
        this.fbo.velocityVariable.material.uniforms.morphTerm.value = params.morphTerm
      })

    if (tl) {
      tl.eventCallback("onUpdate", function () {
        // console.log(params.tweenMorph);
        params.morphTerm = params.tweenMorph
        pane.refresh()
      })
    }
  }
}
