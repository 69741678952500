import Input from "./Input";
import Common from "./Common";
import Output from "./Output";

import gsap from "gsap";

export default class {
  constructor({ canvas }) {
    this.canvas = canvas;
  }
  init() {
    const { canvas } = this;
    Input.init();
    Common.init(canvas);
    this.output = new Output();
    this.resize();
    this.x = this.resize.bind(this);
    window.addEventListener("resize", this.x, false);

    this.y = this.render.bind(this);
    gsap.ticker.add(this.y);

    if (Common.debug) {
      Common.setDebug();
      this.output.setDebug();
    }
  }
  render(t) {
    Input.render(t);
    this.output.render(t);
  }
  resize() {
    Input.resize();
    Common.resize();
    this.output.resize();
  }
  dispose() {
    gsap.ticker.remove(this.y);
    window.removeEventListener("resize", this.x);

    Input.dispose();
    Common.dispose();
    this.output.dispose();
    
    if (Common.debug) {
      Common.debug.dispose();
    }
  }
}
