import Device from "@/pure/Device"
import Common from "@/graphics/Common"

import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js"
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js"
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js"
import { Uniform } from "three"

import vertexShader from "./shaders/vertex.glsl"
import fragmentShader from "./shaders/fragment.glsl"

import { Vector2, TextureLoader } from "three"

export default class PostProcessing {
  params = {
    threshold: 0.25,
    strength: 1,
    radius: 0,
    exposure: 1,
  }

  constructor() {
    const { pixelRatio, viewport } = Device
    const { width, height } = viewport
    this.passes = {}
    this.composers = {}

    this.composer = new EffectComposer(Common.renderer)
    this.composer.setPixelRatio(pixelRatio)
    this.composer.setSize(width, height)
    this.composer.addPass(new RenderPass(Common.scene, Common.camera))

    const irridescent = new TextureLoader().load("/assets/irridescent.jpg")

    this.mainPass = new ShaderPass({
      uniforms: {
        tDiffuse: { value: null },
        velocity: { value: null },
        resolution: new Uniform(new Vector2(width * pixelRatio, height * pixelRatio)),
        gradient: new Uniform(irridescent),
        decay: new Uniform(1),
        time: new Uniform(0),
      },
      vertexShader,
      fragmentShader,
    })
    this.composer.addPass(this.mainPass)
  }
  render(t) {
    this.mainPass.uniforms.time.value = t

    this.composer.render()
  }
  dispose() {
    this.composer.removePass(this.mainPass)
    this.composer.dispose()
    this.mainPass.dispose()
  }
  resize() {
    const { pixelRatio, viewport } = Device
    const { width, height } = viewport
    this.composer.setPixelRatio(pixelRatio)
    this.composer.setSize(width, height)
    this.mainPass.uniforms.resolution.value.set(width * pixelRatio, height * pixelRatio)
  }
}
