<template>
  <a
    :href="target"
    target="_blank"
    @mouseenter="handleHover(true)"
    @mouseleave="handleHover(false)"
    class="link_button">
    <span v-for="n in 2" :key="n" ref="textContainers" class="word">
      {{ label }}
    </span>
  </a>
</template>

<script>
import gsap from "gsap"
import { SplitText } from "gsap/SplitText"
import useDelayElement from "@/composables/useDelayElement"

export default {
  name: "CallToAction",
  props: {
    label: {
      type: String,
      default: "",
    },
    target: {
      type: String,
      default: "_self",
    },
  },

  data() {
    return {
      splits: [],
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.textContainers.forEach((container) => {
        const splitInstance = new SplitText(container, {
          type: "chars, lines",
          linesClass: "lineClass",
          charsClass: "charClass",
        })
        this.splits.push(splitInstance)
      })
    })
  },

  methods: {
    handleHover(isHovered) {
      this.splits.forEach((split) => {
        split.chars.forEach((char, i) => {
          gsap.to(char, {
            ease: "power1.inOut",
            transform: isHovered ? "translate3d(0, 200%, 0)" : "translate3d(0, 0%, 0)",
            duration: useDelayElement(0.4, 0.1, i, split.chars.length - 1, 2),
          })
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.link_button {
  position: relative;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: fit-content;

  color: var(--primary-300);
  padding: 0.5em 0em 0.5em;

  padding: 0;

  font-variation-settings: "wght" 500;
  letter-spacing: 0.4px;

  .word {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &:nth-child(2) {
      color: var(--primary-200);
      position: absolute;
      transform: translate3d(0, -200%, 0);
    }

    ::after {
      content: none;
    }
  }

  .lineClass {
    transform: translate3d(0, 0, 0);
  }

  .charClass {
    margin: auto 0;
    line-height: calc(var(--nav-sizing / 2) - var(--stroke) * 4);
  }
}

.link_button {
  ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: var(--stroke);
    background-color: var(--primary-300);
    bottom: 0;
    left: 0;
    transform: scaleX(1);
    transform-origin: left;
    transition: transform 0.3s;
  }

  &:hover {
    ::after {
      transform: scaleX(0);
      transform-origin: right;
    }
  }
}
</style>
