<template>
  <div class="scroll-container additional-content">
    <span class="scroll" ref="container">
      (
      <div class="scroll-text-container">
        <span v-for="(_, i) in 2" :key="i" :class="i ? 'scroll-ghost' : 'scroll-text'" ref="scroller">
          SCROLL
        </span>
      </div>
      )
    </span>
  </div>
</template>
<script>
import useDelayElement from "@/composables/useDelayElement"
import gsap from "gsap"
import { SplitText } from "gsap/SplitText"

export default {
  data() {
    return {
      scroller: null,
      container: null,
      children: [null],
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.scroller = this.$refs.scroller
      this.container = this.$refs.container
      this.scroller.forEach((el) => {
        const splitter = new SplitText(el, {
          type: "lines, chars",
          linesClass: "linesClass",
          charsClass: "charClass",
        })

        splitter.chars.forEach((char, i) => {
          const duration = useDelayElement(1.2, 0.1, i, splitter.chars.length - 1, 1)

          let tl = gsap.timeline({ repeat: -1, repeatDelay: 3 })

          tl.set(splitter.chars, { y: "5%" }, 0)
          tl.set(this.container, { gap: "0.2em" }, 0)

          tl.to(this.container, { duration: 1.1, gap: "1em", ease: "power2.out" }, 0)
          tl.to(
            char,
            {
              y: "155%",
              duration: duration,
              ease: "power2.inOut",
            },
            "1.2"
          )
          tl.to(this.container, { duration: 1.1, gap: ".2em", ease: "power2.out" }, "3")
        })
      })
    })
  },
}
</script>
<style lang="scss" scoped>
.scroll-container {
  position: absolute;

  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  font-size: smaller;

  bottom: calc(2 * var(--gutter-md));

  .scroll {
    position: relative;

    display: flex;
    align-items: center;
    gap: 1em;

    transform: translate3d(0, -10%, 0);

    .scroll-text-container {
      display: flex;
      align-items: center;
      gap: 0.5em;
      .scroll-text {
        position: relative;
        transform: translateY(0);
      }
      .scroll-ghost {
        position: absolute;
        transform: translateY(-150%);
      }
    }
  }

  @include md {
    bottom: 64px;
  }
}
</style>
