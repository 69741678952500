<template>
  <div class="menu-overlay">
    <RouterLink :to="{ name: 'Home' }" class="cta" @click="() => handleClick('Home')"> Accueil </RouterLink>
    <RouterLink :to="{ name: 'About' }" class="cta" @click="() => handleClick('About')">
      À propos
    </RouterLink>
    <a class="cta" href="mailto:hello@latelier.co" target="_BLANK"> Contact </a>
  </div>
</template>

<script>
import { useRoute } from "vue-router"

export default {
  name: "MenuOverlay",
  setup(props, { emit }) {
    const route = useRoute()

    const handleClick = (name) => {
      if (route.name === name) {
        emit("rewind")
      }
    }
    return {
      handleClick,
    }
  },
}
</script>

<style lang="scss" scoped>
.menu-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  padding: var(--gutter-md);
  top: 0;
  left: 0;
  padding-top: #{55px + 35px};
  padding-bottom: #{55px + 35px};
  box-sizing: border-box;
  background-color: black;
  z-index: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: var(--gutter-md);

  @include md {
    display: none;
  }
}

.cta {
  color: #ffffff;
  text-decoration: none;
  font-size: 48px;
}
</style>
