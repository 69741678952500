<template>
  <div id="scroll-indicator">
    <div class="thumb"></div>
  </div>
</template>

<script>
export default {
  name: "ScrollIndicator",
}
</script>

<style lang="scss" scoped>
#scroll-indicator {
  position: fixed;
  width: 6px;
  height: calc(var(--vh, 1vh) * 20);
  top: calc(var(--vh, 1vh) * 40);
  background-color: #c1c3b540;
  border-radius: 3px;
  right: 16px;
  will-change: opacity;
  @include md-only {
    display: none;
  }
}
.thumb {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--primary-400);
  transform-origin: 0 0;
  border-radius: 3px;
  will-change: transform;
}
</style>
