import * as THREE from "three"
import Common from "@/graphics/Common"
import Input from "@/graphics/Input"

import gsap from "gsap"

import vertexShader from "./shaders/vertex.glsl"
import fragmentShader from "./shaders/fragment.glsl"

function rnd2() {
  return (
    (Math.random() + Math.random() + Math.random() + Math.random() + Math.random() + Math.random() - 3) / 3
  )
}

export default class {
  dummy = new THREE.Object3D()
  cameraPosition = new THREE.Vector3()
  geometry = new THREE.PlaneGeometry(0.15, 0.15, 1)
  initCode = "cos(y * 4.14) * 50.0"
  params = {
    count: 512 * 512,
    opacity: 0,
    color: "#FFFFF0",
    code: this.initCode,
  }
  constructor() {
    this.init()
  }
  init() {
    const { cameraPosition, geometry } = this
    var r = "/assets/cubemap/"
    var urls = [r + "px.png", r + "nx.png", r + "py.png", r + "ny.png", r + "pz.png", r + "nz.png"]
    this.textureCube = new THREE.CubeTextureLoader().load(urls)

    const { params, dummy } = this

    const material = new THREE.RawShaderMaterial({
      transparent: true,
      visible: false,
      side: THREE.DoubleSide,
      uniforms: {
        time: {
          value: 0,
        },
        opacity: {
          value: 0,
        },
        color: {
          value: new THREE.Color(params.color),
        },
        envMap: {
          value: this.textureCube,
        },
        cameraPosition: {
          value: Common.camera.getWorldPosition(cameraPosition),
        },
      },
      vertexShader,
      fragmentShader,
    })
    material
    this.instance = new THREE.InstancedMesh(geometry, material, params.count)
    this.instance.frustumCulled = false

    const speeds = []
    for (let i = 0; i < params.count; i++) {
      const x = 20 * rnd2()
      const y = 120 * Math.random()
      const z = 20 * rnd2()

      const speed = rnd2() * 0.5 + 0.5

      dummy.position.set(x, y, z)

      dummy.rotation.x = Math.random() * Math.PI * 2
      dummy.rotation.y = Math.random() * Math.PI * 2
      dummy.rotation.z = Math.random() * Math.PI * 2

      const scale = speed

      dummy.scale.set(scale, scale, scale)

      dummy.updateMatrix()

      this.instance.setMatrixAt(i, dummy.matrix)
      speeds.push(speed)
      speeds.push(speed)
      speeds.push(speed)
      speeds.push(speed)
    }
    geometry.setAttribute("speed", new THREE.InstancedBufferAttribute(new Float32Array(speeds), 1))

    Common.views[0].add(this.instance)
  }
  enter() {
    const { params } = this
    return new gsap.timeline({
      onStart: () => {
        this.instance.material.visible = true
      },
    }).to(params, {
      opacity: 1,
      duration: 1.5,
      ease: "none",
      onUpdate: () => {
        this.instance.material.uniforms.opacity.value = params.opacity
      },
    })
  }
  leave() {
    const { params } = this
    return new gsap.timeline({
      onComplete: () => {
        this.instance.material.visible = true
      },
    }).to(params, {
      opacity: 0,
      duration: 0.5,
      ease: "none",
      onUpdate: () => {
        this.instance.material.uniforms.opacity.value = params.opacity
      },
    })
  }
  render(t) {
    t
    this.instance.material.uniforms.time.value = t

    const rotationX = -Input.lerpCoords.y * 0.0314
    const rotationY = Input.lerpCoords.x * 0.0314
    this.instance.rotation.x = rotationX
    this.instance.rotation.y = rotationY
  }
  dispose() {
    Common.views[0].remove(this.instance)
  }
  resize() {}
  setDebug(pane) {
    const { params, initCode } = this
    this.debugFolder = pane.addFolder({
      title: "Waves",
      expanded: true,
    })
    this.debugFolder.addBinding(params, "code").on("change", () => {
      this.instance.material.vertexShader = vertexShader.replace(initCode, params.code)
      this.instance.material.needsUpdate = true
    })

    this.debugFolder
      .addBinding(params, "color", {
        label: "color",
        view: "color",
      })
      .on("change", () => {
        this.instance.material.uniforms.color.value = new THREE.Color(params.color)
      })
  }
}
