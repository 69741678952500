export default function useDelayElement(duration, delay, index, total, version) {
  const m = Math
  const x = index / total
  version = version || 1
  // retourne un nombre entre 0 et -1 en fonction de x
  const algorythm = (version) => {
    if (version === 1) {
      return m.sin(x * m.PI - m.PI)
    } else if (version === 2) {
      return m.sin(x * (m.PI * 0.25))
    }
  }
  // const duration = 0.6
  // const delay = 0.1
  // retourne la valeur de duration soit 0.6 < duration < 0.7
  return duration + algorythm(version) * delay
}
