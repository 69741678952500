export default [
  {
    name: 'AltareaPartenaires',
    tags: [
      'Application hybride',
      'Développement',
      'UX / UI Design'
    ],
    title: 'ALTAREA PARTENAIRES',
    client: "ALTAREA",
    project: "PARTENAIRES",
    image: '/assets/renaissance/villagesdelarche.jpeg',
  },
  {
    name: 'JllNxtoffice',
    tags: [
      '3D',
      'Développement',
      'Expérience'
    ],
    title: 'JLL Nxt Office',
    client: "JLL",
    project: "Nxt Office",
    image: '/assets/nxtoffice/jllnxtoffice.jpeg',
  },
  {
    name: 'InfogreffeMarketplace',
    tags: [
      'UX / UI Design',
      'Développement web',
    ],
    title: 'INFOGREFFE MARKETPLACE',
    client: "INFOGREFFE",
    project: "MARKETPLACE",
    image: '/assets/infogreffe/cityscape.png',
  }
];
