<template>
  <div class="footer-section">
    <div class="section">
      <div class="order">
        <div class="layout">
          <h5>Contact</h5>

          <div class="text-left">
            <LinkButton label="hello@latelier.co" target="mailto:hello@latelier.co" />
            <LinkButton label="conseil.latelier.co" target="https://conseil.latelier.co" />
          </div>
        </div>

        <div class="layout">
          <h5>Nous rejoindre</h5>

          <div class="text-left">
            <LinkButton label="LinkedIn" target="https://www.linkedin.com/company/latelier.co/" />
            <LinkButton
              label="Welcome to the jungle"
              target="https://www.welcometothejungle.com/fr/companies/l-atelier" />
          </div>
        </div>
      </div>

      <div class="order">
        <div class="layout">
          <h5>Paris</h5>

          <span class="text-left">
            <span>46 rue de l’Arbre Sec</span>
            <span>75001 Paris, France</span>
          </span>
        </div>

        <div class="layout">
          <h5>Montréal</h5>

          <span class="text-left">
            <span>1080 côte du Beaver Hall #2100</span>
            <span>QC H2Z 1S8 Montréal, Canada</span>
          </span>
        </div>
      </div>
    </div>

    <div class="line-separator"></div>

    <div class="section credit">
      <div class="copyright">© L'Atelier {{ year }}</div>
      <span class="conseil">
        Conseils :
        <LinkButton label="conseil.latelier.co" target="https://conseil.latelier.co" />
      </span>
    </div>
  </div>
</template>

<script>
import LinkButton from "@/components/LinkButton.vue"
export default {
  name: "FooterSection",
  components: {
    LinkButton,
  },
  data() {
    return {
      year: new Date().getFullYear(),
    }
  },
}
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
  color: inherit;
}

.footer-section {
  border-top: 1px solid rgba(77, 77, 77, 1);
  color: rgba(236, 236, 236, 1);

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  font-style: normal;

  line-height: 110%;

  .conseil {
    display: flex;
    flex-direction: row;

    width: auto;

    gap: 8px;
  }

  .section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    align-items: center;

    padding: var(--gutter-xs) var(--gutter-md);

    .order {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      width: 100%;

      .layout {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: auto;

        .text-left {
          display: flex;
          flex-direction: column;
          gap: 8px;
          font-variation-settings: "wght" 500;
          letter-spacing: 0.4px;

          @include md-only {
            align-items: left;
          }
        }
      }

      .address {
        font-size: 14px;
        line-height: 120%;
      }

      @include xl-only {
        flex-direction: column;
        justify-content: space-between;
        width: auto;
        height: max-content;

        gap: 4rem;
      }
    }
    @include xl-only {
      justify-content: space-around;

      padding: var(--gutter-md) var(--gutter-md);
    }

    @include md-only {
      flex-direction: column;
      align-items: flex-start;
      gap: 4rem;

      padding: var(--gutter-md) var(--gutter-md);
    }
  }
}

.credit {
  justify-content: space-between !important;
}

.line-separator {
  margin-left: var(--gutter-md);
  margin-right: var(--gutter-md);
  height: 1px;
  background-color: rgba(77, 77, 77, 1);
}
</style>
