<template>
  <div class="section">
    <div class="block">
      <h2 class="h2-primary"><span class="before"></span>À propos</h2>
      <div class="col-parent">
        <div class="editorial diptyque">
          <div class="col">
            <h5>ARTISANS DU NUMÉRIQUE.</h5>
            <p>
              Chez L'Atelier, nous sommes les artisans du numérique. Fusionnant créativité et expertise
              technique, nous façonnons des solutions digitales taillées sur mesure pour répondre à vos
              besoins.
            </p>
          </div>
          <div class="col">
            <h5>PROJETS DE DEMAIN.</h5>
            <p>
              Par le biais du Code, du Design et de l'Agilité, nous construisons les projets de demain.
              Êtes-vous prêt à écrire le prochain chapitre de cette aventure à nos côtés ?
            </p>
          </div>
        </div>
        <RouterLink :to="{ name: 'About' }">
          <CallToAction label="En savoir plus" />
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script>
import CallToAction from "@/components/CallToAction"
export default {
  name: "AboutSection",
  components: { CallToAction },
}
</script>

<style lang="scss" scoped>
.section {
  position: relative;
  display: flex;

  padding: var(--gutter-md);
  color: #ffffff;
  @include md {
    justify-content: end;
  }

  .block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--gutter-md);

    .col-parent {
      display: flex;
      flex-direction: column;
      gap: var(--gutter-xs);
      padding-left: 25vw;

      .editorial {
        padding: 0;

        @include md-only {
          gap: var(--gutter-md);
        }
      }

      a {
        width: fit-content;
        margin: 0;
      }

      @include lg-only {
        gap: var(--gutter-sm);
        padding-left: 0;
      }
    }
  }
}
</style>
