<template>
  <div class="gabarit project-view">
    <div class="half view" data-idx="1">
      <img src="/assets/nxtoffice/jllnxtoffice.jpeg" class="project-image" />
    </div>
    <div class="main-title">
      <div class="project-title-first">
        <h1 class="line h1-primary">JLL</h1>
        <small class="subtitle">
          <div class="small-line">UNE SALLE IMMERSIVE POUR</div>
          <div class="small-line">TRANSFORMER LA RECHERCHE DE</div>
          <div class="small-line">BUREAUX EN EXPÉRIENCE</div>
          <div class="small-line">COLLABORATIVE.</div>
        </small>
      </div>

      <h1 class="line h1-secondary">NXT OFFICE</h1>
    </div>
    <div class="specifications">
      <div class="specification">
        <h3>GESTION DE PROJET</h3>
        <span>1 Product Owner, 2 UX/UI, 5 développeurs</span>
        <span>6 sprints de 2 semaines</span>
      </div>
      <div class="specification">
        <h3>STACK</h3>
        <span>VueJS / .Net Core / Azure</span>
        <span>Cinema 4D / Blender</span>
      </div>
      <div class="specification">
        <h3>FORMAT</h3>
        <span>Desktop</span>
        <span>Ecran télé tactile</span>
      </div>
    </div>
    <div class="description">
      L’application NxT Office accélère la recherche de bureaux et permet d’accéder à l'ensemble des offres du
      marché européen. Détenteur d’un panel de data d’aide à la décision, JLL accompagne ses clients de la
      recherche à la visite de ces biens, en toute confidentialité.
    </div>
    <div class="separator-line"></div>
    <div class="marquee">
      <div class="marquee__inner" aria-hidden="true">
        <span>NXT OFFICE</span>
        <span>NXT OFFICE</span>
        <span>NXT OFFICE</span>
        <span>NXT OFFICE</span>
      </div>
    </div>
    <div class="marquee reverse">
      <div class="marquee__inner" aria-hidden="true">
        <span>NXT OFFICE</span>
        <span>NXT OFFICE</span>
        <span>NXT OFFICE</span>
        <span>NXT OFFICE</span>
      </div>
    </div>
    <div class="fullscreen" ref="fullscreen">
      <img src="/assets/nxtoffice/main.jpg" />
    </div>
    <div class="context">
      <h3>Contexte</h3>
      <p>
        Leader mondial du conseil en immobilier pour les professionnels, JLL accompagne les entreprises,
        propriétaires et investisseurs sur l'ensemble de leurs enjeux en immobilier commercial (bureaux,
        commerces, entrepôts, locaux d’activité, hôtels, résidences avec services). JLL a notamment développé
        plusieurs outils permettant d’optimiser la recherche d’actif en fonction des besoins de chaque
        clients; NxT Office est la plateforme que nous avons imaginé ensemble pour rechercher des bureaux, en
        France et à l’international.
      </p>
    </div>
    <div class="dyptique">
      <div class="image-helper">
        <img src="/assets/nxtoffice/dip1.jpg" />
      </div>
      <div class="image-helper">
        <img src="/assets/nxtoffice/dip1.jpg" />
      </div>
    </div>
    <div class="fullscreen">
      <img src="/assets/nxtoffice/full1.jpg" />
    </div>
    <div class="context">
      <h3>Problématique</h3>
      <p>
        NxT Office devait pouvoir être utilisée dans une salle physique située dans les locaux de JLL France à
        Paris. Les différentes pages de NxT office sont projetées sur trois écrans télé, ainsi qu’un écran
        tactile qui permet de se déplacer sur la carte du territoire ou encore dans les visites 3D des actifs
        sélectionnés. Véritable expérience immersive et collaborative entre conseillers JLL et clients finaux,
        le succès de la salle de Paris a amené la création d’une seconde salle NxT à Londres, Singapour et
        Francfort.
      </p>
      <p>
        Le développement du télétravail et des visioconférences ont également permis la création de NxT
        Showroom que nous avons développé avec JLL : une modélisation 3D de la salle immersive pour profiter
        de l’expérience NxT n’importe où dans le monde.
      </p>
    </div>
    <div class="max-width">
      <img src="/assets/nxtoffice/full2.jpg" />
    </div>
    <NextProject name="InfogreffeMarketplace" />
    <FooterSection />
  </div>
</template>

<script>
import { inject, onMounted, onUnmounted, ref } from "vue"
import NextProject from "./NextProject.vue"
import FooterSection from "@/components/FooterSection.vue"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
export default {
  name: "ViewProject",
  components: { NextProject, FooterSection },
  setup() {
    const graphics = inject("Graphics")
    const fullscreen = ref(null)

    onMounted(() => {
      ScrollTrigger.create({
        trigger: fullscreen.value,
        start: "top bottom",
        end: "bottom top",
        scrub: true,
        animation: gsap.fromTo(
          fullscreen.value.querySelector("img"),
          { yPercent: -25 },
          { yPercent: 25, ease: "none" }
        ),
      })
    })
    onUnmounted(() => {
      graphics.output.unbindPage()
    })
    return {
      fullscreen,
    }
  },
}
</script>

<style lang="scss" scoped></style>
