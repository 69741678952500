<template>
  <div class="gabarit home" ref="view">
    <div class="view center">
      <div class="main-title" v-if="!isSmallScreen">
        <h1 class="line first h1-primary">SUBLIMER VOS IDÉES</h1>

        <div class="middle">
          <h1 class="line h1-secondary">EN PRODUITS</h1>

          <small class="subtitle">
            <div class="small-line">L’ATELIER EST UNE ÉQUIPE D’ARTISANS</div>
            <div class="small-line">DU WEB PASSIONNÉS, MENANT DES</div>
            <div class="small-line">PROJETS AUDACIEUX.</div>
          </small>
        </div>

        <h1 class="line last h1-primary">D'EXCEPTION</h1>
      </div>
      <div class="main-title" v-else>
        <h1 class="line first h1-primary">
          SUBLIMER VOS IDÉES <span class="h1-secondary">EN PRODUITS</span> <br />D'EXCEPTION
        </h1>

        <div class="middle">
          <small class="subtitle">
            <div class="small-line">
              L’ATELIER EST UNE ÉQUIPE D’ARTISANS DU WEB PASSIONNÉS, MENANT DES PROJETS AUDACIEUX.
            </div>
          </small>
        </div>
      </div>

      <div class="center-absolute">
        <ScrollDownTicker />
      </div>
    </div>
    <AboutSection />
    <div class="project-section editorial section-heading">
      <h2 class="baseline"><span class="before"></span>Projets</h2>
      <p>
        À travers nos projets, notre objectif est de traduire vos besoins en produits innovants et
        user-friendly qui engagent, inspirent et amènent à des résultats concrets.
      </p>
    </div>
    <div class="projects">
      <RouterLink
        v-for="({ name, title, tags, image }, idx) in projects"
        :key="name"
        class="project-helper interactable"
        :to="{ name }">
        <div :class="['project', name]" ref="project" :data-idx="idx">
          <img :src="image" class="project-image" />
        </div>
        <div class="relative-box">
          <div class="col">
            <div class="tags">
              <span class="tag" v-for="(tag, i) in tags" :key="i">{{ tag.toUpperCase() }}</span>
            </div>
            <div class="title">{{ title }}</div>
          </div>
          <CallToAction label="Découvrir" />
        </div>
      </RouterLink>
    </div>
    <FooterSection />
  </div>
</template>

<script>
import { inject, ref, onMounted, onUnmounted } from "vue"
import gsap from "gsap"

import AboutSection from "./AboutSection.vue"
import CallToAction from "@/components/CallToAction"
import FooterSection from "@/components/FooterSection.vue"
import projects from "@/views/Projects/data"
import ScrollDownTicker from "@/components/ScrollDownTicker.vue"

import { useEditorialAnimations } from "@/composables/editorialAnimations"

export default {
  name: "ViewHome",
  components: {
    AboutSection,
    CallToAction,
    FooterSection,
    ScrollDownTicker,
  },
  setup() {
    const isSmallScreen = ref(window.innerWidth <= 811)
    const graphics = inject("Graphics")
    let st = null
    const view = ref(null)
    const project = ref(null)

    useEditorialAnimations(view)

    const handleResize = () => {
      isSmallScreen.value = window.innerWidth <= 811
    }

    onMounted(() => {
      graphics.output.bindPage({
        projects: project.value,
      })
      const tl = gsap.timeline()
      tl.add(graphics.output.components.wave.enter(), "a")

      window.addEventListener("resize", handleResize)
    })

    onUnmounted(() => {
      if (st) st.kill()
      window.removeEventListener("resize", handleResize)
    })

    return {
      project,
      projects,
      view,
      isSmallScreen,
    }
  },
}
</script>

<style lang="scss" scoped>
.center {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--gutter-md);
  justify-content: center;
  box-sizing: border-box;
}

.center-absolute {
  display: flex;
  position: absolute;
  width: 100%;
  justify-content: center;
  bottom: 0%;
  left: 50%;
  transform: translate3d(-50%, 0%, 0);
}

.main-title {
  @include md-only {
    justify-content: flex-start;

    h1 {
      margin: 0 0;
      font-size: 8vw;
      white-space: normal;

      span {
        font-size: 8vw;
        white-space: normal;
      }
    }

    .middle {
      padding: 3vw 0 0 0;

      .small-line {
        margin: 0;
        font-size: 1.5vw;
        white-space: normal;

        &:nth-child(1) {
          padding-left: 0px;
        }
      }
    }
  }

  @include sm-only {
    h1 {
      font-size: 9vw;
      white-space: wrap;

      span {
        font-size: 9vw;
        white-space: wrap;
      }
    }

    .middle {
      padding: 3vw 0 0 0;

      .small-line {
        margin: 0;
        font-size: 2.5vw;
        white-space: wrap;

        &:nth-child(1) {
          padding-left: 0px;
        }
      }
    }
  }
}

.ticker {
  // position: absolute;
  // left: 50%;
  // transform: translate3d(-50%, 0, 0);
}

.tag {
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: 118%; /* 18.88px */
  letter-spacing: 0.4px;

  @include md-only {
    font-size: 0.5rem;
  }

  & + .tag {
    &:before {
      content: " - ";
    }
  }
}

.projects {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  perspective: 1000px;
  padding: var(--gutter-md);
  gap: var(--gutter-xl);
}

.project-helper {
  width: 100%;
  color: inherit;
  text-decoration: none;

  .title {
    font-size: 4vw;
    font-style: normal;
    font-weight: 400;
    line-height: 118%;
    letter-spacing: 1.6px;
  }
}
.project {
  width: 100%;
  height: 0;
  cursor: pointer;
  position: relative;
  padding-bottom: 42.553%;
  display: block;
  border-radius: 2px;
  overflow: hidden;

  .project-image {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    display: none;
  }
}

.relative-box {
  display: flex;
  position: relative;

  flex-direction: row;
  justify-content: space-between;

  margin-top: var(--gutter-xxs);

  .col {
    display: flex;
    flex-direction: column;
    gap: var(--gutter-xxs);
  }

  .call-to-action {
    @include md-only {
      display: none;
    }
  }
}

.section {
  padding: var(--gutter-md);
}
</style>
