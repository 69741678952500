import { createApp, provide, h } from "vue"
import App from "./App.vue"
import router from "./router"

import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import SplitText from "gsap/SplitText"
import ScrollToPlugin from "gsap/ScrollToPlugin"

import Graphics from "@/graphics"

gsap.registerPlugin(ScrollTrigger, SplitText, ScrollToPlugin)

createApp({
  setup() {
    provide(
      "Graphics",
      new Graphics({
        canvas: document.body.querySelector("#graphics"),
      })
    )
  },
  render: () => h(App),
})
  .use(router)
  .mount("#app")
