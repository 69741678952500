import { Vector2 } from "three"
import MobileDetect from "mobile-detect"

const md = new MobileDetect(window.navigator.userAgent);

export default {
  viewport: new Vector2(window.innerWidth, window.innerHeight),
  pixelRatio: Math.min(window.devicePixelRatio, 2),
  isMobile: md.mobile()
}
