<template>
  <div class="header-navigation">
    <div class="nav">
      <RouterLink class="home-cta" :to="{ name: 'Home' }" @click="() => handleClick('Home')">
        <img src="../assets/clients/logos/logo-atelier.svg" alt="logo" />
      </RouterLink>

      <div class="tab-framer">
        <RouterLink :to="{ name: 'Home' }" class="cta" @click="() => handleClick('Home')">
          <LinkButton label="Accueil" />
        </RouterLink>
        <RouterLink :to="{ name: 'About' }" class="cta">
          <LinkButton label="À propos" />
        </RouterLink>
      </div>

      <ContactButton />
    </div>

    <div v-if="!menuOpened" @click="handleMenuState" class="icon-container">
      <img src="/assets/menu_icon.svg" />
    </div>

    <div v-if="menuOpened" @click="handleMenuState" class="icon-container">
      <img src="/assets/cross_icon.svg" />
    </div>

    <MenuOverlay @rewind="handleRewind" v-if="menuOpened" />
  </div>
</template>

<script>
import LinkButton from "@/components/NavLink.vue"
import { useRoute } from "vue-router"
import { gsap } from "gsap"
import { ref } from "vue"

import ContactButton from "./ContactButton.vue"
import MenuOverlay from "@/components/MenuOverlay.vue"

export default {
  name: "HeaderNavigation",
  components: { LinkButton, ContactButton, MenuOverlay },
  setup(props, { emit }) {
    const entered = ref(false)

    const router = useRoute()
    const menuOpened = ref(false)

    const handleClick = () => {
      if (router.name === "Home") {
        emit("rewind")
      }
    }

    const handleMenuState = () => {
      menuOpened.value = !menuOpened.value
    }
    const handleRewind = () => {
      menuOpened.value = false

      gsap.to(window, {
        ease: "power4.out",
        duration: 1,
        scrollTo: {
          y: 0,
        },
      })
    }
    return {
      handleClick,
      handleMenuState,
      handleRewind,
      menuOpened,
      entered,
    }
  },
}
</script>

<style lang="scss" scoped>
.header-navigation {
  position: fixed;
  display: flex;

  overflow: hidden;

  justify-content: center;

  top: var(--gutter-xxs);
  left: var(--gutter-md);
  right: var(--gutter-md);

  z-index: 10;

  @include md-only {
    box-sizing: border-box;

    top: 0;
    left: 0;
    right: 0;

    width: 100vw;

    justify-content: space-between;

    padding: var(--gutter-xxs);
  }

  .nav {
    position: relative;
    display: flex;
    align-items: center;

    gap: var(--gutter-xs);

    background: var(--primary-800);
    padding: 5px 5px 5px 5px;
    border-radius: 3px;

    @include md-only {
      padding: 10px;
      & > *:nth-child(n + 2) {
        display: none;
      }
    }
  }

  .home-cta {
    display: flex;

    img {
      width: var(--nav-sizing);
      aspect-ratio: 1 / 1;
    }
  }

  .cta {
    display: flex;
    align-items: center;

    color: var(--primary-200, #f2f2f2);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    text-decoration: none;
  }

  a {
    text-decoration: none;
  }

  .tab-framer {
    display: flex;

    padding: 0 0.75em;

    gap: var(--gutter-xxs);
  }

  .icon-container {
    display: flex;

    width: var(--nav-sizing);
    aspect-ratio: 1 / 1;

    background-color: #292929;
    border-radius: 2px;
    padding: 10px;

    align-items: center;
    justify-content: center;

    cursor: pointer;

    z-index: 11;

    @include md {
      display: none;
    }
  }
}
</style>
