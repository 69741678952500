<template>
  <HeaderNavigation @rewind="handleRewind" v-if="entered" />

  <router-view v-slot="{ Component }">
    <Transition
      @enter="onEnter"
      @enter-cancelled="onEnterCancelled"
      @leave="onLeave"
      @leave-cancelled="onLeaveCancelled"
      mode="out-in"
      :css="false">
      <component :is="Component" v-if="entered" />
    </Transition>
  </router-view>
  <div class="font_preload">
    <span style="font-family: 'Montserrat', Arial, sans-serif">a</span>
    <span style="font-family: 'Mulish', Arial, sans-serif">c</span>
  </div>
  <ScrollIndicator />
</template>

<script>
import { inject, ref, onMounted, onUnmounted } from "vue"
import { useRouter } from "vue-router"
import gsap from "gsap"
import HeaderNavigation from "@/components/HeaderNavigation.vue"
import ScrollIndicator from "@/components/ScrollIndicator.vue"
import ScrollIndication from "@/pure/ScrollIndication"
import ScrollTrigger from "gsap/ScrollTrigger"
import Device from "@/pure/Device"

export default {
  components: { HeaderNavigation, ScrollIndicator },
  setup() {
    let st = null
    let enterTl = null
    let leaveTl = null
    const entered = ref(false)
    const graphics = inject("Graphics")
    const router = useRouter()

    onMounted(() => {
      graphics.init()
      const tl = new gsap.timeline({
        onComplete() {
          entered.value = true
        },
      })
      tl.add(graphics.output.components.particles.enter())
      ScrollIndication.init()
    })
    onUnmounted(() => {
      if (st) st.kill(true)
      graphics.dispose()
      ScrollIndication.dispose()
    })
    const bindView = () => {
      st = ScrollTrigger.create({
        trigger: document.body.querySelector(".gabarit"),
        start: "top top",
        end: "bottom bottom",
        onUpdate: (self) => {
          const progress = self.progress.toFixed(6)
          graphics.output.updateView({ y: progress, max: self.end / Device.viewport.height })
          ScrollIndication.progress = parseFloat(progress)
          ScrollIndication.maxScroll = self.end
        },
      })
      ScrollIndication.maxScroll = st.end
      ScrollIndication.refresh()
    }
    const onEnter = (el, done) => {
      onEnterCancelled()
      if (st) {
        st.kill()
        st = null
      }
      bindView()
      gsap.set(window, { scrollTo: 0 })
      if (router.currentRoute.value.meta.project) {
        const item = el.querySelector(".view.half")
        const tl = gsap.timeline({
          onComplete: done,
        })
        tl.add(graphics.output.completeProject(item))
      } else {
        done()
      }
    }
    const onEnterCancelled = () => {
      if (enterTl) enterTl.kill()
    }

    const onLeave = (el, done) => {
      onLeaveCancelled()
      leaveTl = gsap.timeline({
        onComplete: () => {
          done()
        },
      })
      if (el.classList.contains("home")) {
        if (router.currentRoute.value.meta.project) {
          const subTl = gsap.timeline({
            onStart: () => {
              document.body.style.overflow = "hidden"
            },
            onComplete: () => {
              document.body.style.removeProperty("overflow")
            },
          })
          const project = el.querySelector(`.project.${router.currentRoute.value.name}`)
          const idx = project.dataset.idx
          subTl
            .add(graphics.output.components.wave.leave(), "a")
            .to(
              ".gabarit",
              {
                alpha: 0,
              },
              "a"
            )
            .to(
              window,
              {
                ease: "circ.inOut",
                duration: 1,
                scrollTo: {
                  y: project,
                  offsetY: window.innerHeight * 0.5 - project.offsetHeight * 0.5,
                },
              },
              "a"
            )
            .add(graphics.output.openProject(parseInt(idx)), "a")
          leaveTl.add(subTl, "a")
        } else {
          const subTl = gsap.timeline({
            onComplete: () => {
              graphics.output.unbindPage()
            },
          })
          subTl.add(graphics.output.components.wave.leave(), "a")
          leaveTl.add(subTl, "a")
          leaveTl.to(
            el,
            {
              alpha: 0,
            },
            "a"
          )
        }
      } else {
        leaveTl.to(el, {
          alpha: 0,
        })
      }
    }
    const onLeaveCancelled = () => {
      if (leaveTl) leaveTl.kill()
    }

    return {
      entered,
      onEnter,
      onEnterCancelled,
      onLeave,
      onLeaveCancelled,
    }
  },
}
</script>

<style lang="scss">
@import "@/styles/index.scss";
:root {
  --accent: #06ec7b;
  --text: #fdfdfe;
}

html,
body {
  font-family: "Montserrat", Arial, sans-serif;
  height: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  color: var(--primary-100);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variation-settings: "wght" 500;
  overscroll-behavior: none;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
}

canvas#graphics {
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.view {
  box-sizing: border-box;
  height: 100vh;
}
.logo {
  height: 26px;
  position: absolute;
  left: 60px;
  top: 60px;
  opacity: 0.6;
}

.layout,
.editorial {
  h3 {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    letter-spacing: 0.3px;
    @include md {
      margin-bottom: var(--gutter-xxs);
    }
    perspective: 800px;
  }

  h4 {
    .line {
      overflow: hidden;
    }
  }

  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: 0.192px;
    max-width: 32em;
    perspective: 800px;
    &:nth-child(1) {
      margin-top: 0;
    }
  }
}

.main-title {
  display: flex;
  width: 100%;
  flex-direction: column;

  margin-left: auto;
  margin-right: auto;

  .line {
    position: relative;
    overflow: hidden;
    line-height: 110%;
    perspective: 90vw;

    & > .word {
      position: relative;
    }
  }

  .first {
    margin: 0 auto;
  }

  .middle {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: var(--gutter-md);
    gap: 5vw;
  }

  b {
    color: var(--primary-100);
    text-align: center;
    letter-spacing: 0;
  }

  small {
    display: flex;
    width: auto;
    flex-direction: column;
    gap: 0.2em;
    padding-top: 2%;

    .small-line {
      font-size: 1.1vw;
      line-height: 130%;
      overflow: hidden;
      white-space: nowrap;

      &:nth-child(1) {
        padding-left: 32px;
      }
    }
  }

  .last {
    display: flex;
    justify-content: center;
  }
}

.font_preload {
  opacity: 0;
  position: absolute;
  z-index: -1;
  top: 0;
  pointer-events: none;
}

.section {
  padding: var(--gutter-md);
}
</style>
