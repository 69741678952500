import { ref, onMounted, onUnmounted } from "vue"
import gsap from "gsap"
import { SplitText } from "gsap/SplitText"
import CustomEase from "gsap/CustomEase"
import ScrollTrigger from "gsap/ScrollTrigger"
import Device from "@/pure/Device"

gsap.registerPlugin(CustomEase)

const selector = `
  h2,
  .editorial h3,
  .editorial h4,
  .editorial p
`

const wordsAnimated = ["h2", "H3", "H4", "P"]

export function useEditorialAnimations(templateRef) {
  if (Device.isMobile) return

  let $text = null
  let $smallLines = null
  let $children = [null]
  let $container = [null]
  let $additionalContent = [null]

  const tl = ref(null)
  const ease = CustomEase.create("custom", "M0,0 C0.104,0.604 0.23,1.023 1,1 ")

  let $delay = 0.11

  let triggers = null

  onMounted(() => {
    const imgs = templateRef.value.querySelectorAll("img")
    ;[...imgs].forEach((img) => {
      img.onload = function () {
        ScrollTrigger.refresh()
      }
    })
    if (
      !templateRef.value.querySelector(".main-title .word") &&
      !templateRef.value.querySelector(".main-title .split-small-line-word")
    ) {
      $text = new SplitText(templateRef.value.querySelectorAll(".line"), {
        type: "chars, lines",
        linesClass: "animation-line",
        charsClass: "char",
      })

      $smallLines = new SplitText(templateRef.value.querySelectorAll(".small-line"), {
        type: "chars, words",
        wordsClass: "split-small-line-word",
        charsClass: "small-char",
      })

      $additionalContent = templateRef.value.querySelectorAll(".additional-content")

      $text.container = templateRef.value.querySelectorAll(".line")
    } else {
      $text = {
        lines: templateRef.value.querySelectorAll(".animation-line"),
        container: templateRef.value.querySelectorAll(".line"),
        chars: templateRef.value.querySelectorAll(".char"),
      }
      $smallLines = {
        chars: templateRef.value.querySelectorAll(".small-char"),
      }
      $additionalContent = templateRef.value.querySelectorAll(".additional-content")

      $text.container = templateRef.value.querySelectorAll(".line")
    }

    const timelime = gsap.timeline()

    const animationProps = ref({
      conatinerY: "-70%",
      parentTransform: "translate3d(0, 30%, -3em) rotateX(-1.57rad)",
      childY: "100%",
    })

    // container animation

    $container = Array.from($text.container)

    $container.forEach((container, i) => {
      $text.container = container

      gsap.set($text.container, {
        clipPath: "polygon(0 0, 100% 0, 100% -200%, 0 -200%)",
      })

      gsap.to($text.container, {
        clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
        duration: 2,
        ease: ease,
        delay: i * $delay * 2.5,
      })
    })

    // parent animation

    $text.lines.forEach((parent, i) => {
      gsap.set(parent, {
        transformOrigin: "bottom center",
        transform: `${animationProps.value.parentTransform}`,
      })

      gsap.to(parent, {
        transform: `translate3d(0, 0, 0) rotateX(0)`,
        duration: 2,
        ease: ease,
        delay: 0.5 + i * $delay * 2.5,
      })

      // chars animation

      $children = []

      $children.push(parent.children)

      $children.forEach((chars) => {
        chars = Array.from(chars)

        gsap.set(chars, {
          y: animationProps.value.childY,
          rotateZ: -1,
        })

        chars.forEach((char, idx) => {
          idx /= chars.length

          let $d = i * 0.1 + idx
          $d *= 0.6

          gsap.to(char, {
            y: "0%",
            rotateZ: 0,
            duration: 2 - $d,
            ease: ease,
            delay: 0.2 + $d,
          })
        })
      })
    })

    // small lines animation

    gsap.set($smallLines.chars, {
      alpha: 0,
      y: "100%",
    })
    tl.value = timelime.to(
      $smallLines.chars,
      {
        stagger: 0.005,
        ease: "circ.out",
        duration: 0.8,
        alpha: 1,
        y: "0%",
      },
      "1.5"
    )

    // additional content animation

    $additionalContent.forEach((content) => {
      gsap.set(content, {
        opacity: 0,
        y: "100%",
      })

      tl.value = timelime.to(
        content,
        {
          opacity: 1,
          y: "0%",
          duration: 1,
          ease: "circ.out",
        },
        "2"
      )
    })

    templateRef.value.querySelectorAll(selector).forEach((item) => {
      if (wordsAnimated.includes(item.tagName)) {
        if (item.tagName === "P") {
          new SplitText(item, {
            type: "words",
            linesClass: "line",
            wordsClass: "word",
          })
        } else {
          new SplitText(item, {
            type: "lines, words, chars",
            linesClass: "line",
            wordsClass: "word",
            charsClass: "char",
          })
        }
      } else if (item.tagName === "H2") {
        new SplitText(item, {
          type: "words,chars",
          wordsClass: "word",
          charsClass: "char",
        })
      }
    })

    templateRef.value.querySelectorAll(selector).forEach((item) => {
      if (wordsAnimated.includes(item.tagName)) {
        if (item.tagName === "P") {
          gsap.set(item.querySelectorAll(".word"), {
            alpha: 0.3,
            y: "100%",
          })
        } else {
          gsap.set(item.querySelectorAll(".word"), {
            y: 50,
          })
        }
      } else if (item.tagName === "H2") {
        gsap.set(item.querySelectorAll(".char"), {
          alpha: 0,
          x: 30,
        })
        gsap.set(item.querySelectorAll(".before"), {
          scaleX: 0,
          x: "-150%",
        })
      }
    })

    triggers = ScrollTrigger.batch(templateRef.value.querySelectorAll(selector), {
      start: "top 90%",

      onEnter: (batch) => {
        batch.forEach((item, idx) => {
          const tl = gsap.timeline()
          if (wordsAnimated.includes(item.tagName)) {
            if (item.tagName === "P") {
              tl.to(
                item.querySelectorAll(".word"),
                {
                  alpha: 1,
                  y: "0%",
                  ease: "circ.out",
                  duration: 0.6,
                  stagger: 0.005,
                  delay: 0.1,
                },
                "<"
              )
            } else {
              tl.to(
                item.querySelectorAll(".word"),
                {
                  y: "0%",
                  ease: ease,
                  duration: 0.6,
                  stagger: 0.008,
                },
                idx === 0 ? 0 : ">"
              )
            }
          } else if (item.tagName === "H2") {
            tl.to(
              item.querySelectorAll(".before"),
              {
                x: "0%",
                scaleX: 1,
                ease: "circ.out",
                duration: 1,
              },
              idx === 0 ? 0 : "<"
            ).to(
              item.querySelectorAll(".char"),
              {
                alpha: 1,
                x: 0,
                rotationX: 0,
                ease: "sine.out",
                duration: 0.5,
                stagger: 0.0628,
              },
              idx === 0 ? 0 : "<"
            )
          }
        })
      },
    })

    triggers.concat(
      ScrollTrigger.batch(templateRef.value.querySelectorAll(selector), {
        onLeaveBack: (batch) => {
          batch.forEach((item) => {
            if (wordsAnimated.includes(item.tagName)) {
              if (item.tagName === "P") {
                gsap.set(item.querySelectorAll(".word"), {
                  alpha: 0.3,
                  y: "150%",
                  overwrite: true,
                })
              } else {
                gsap.set(item.querySelectorAll(".word"), {
                  y: 50,
                  overwrite: true,
                })
              }
            } else if (item.tagName === "H2") {
              gsap.set(item.querySelectorAll(".char"), {
                alpha: 0,
                x: 30,
                overwrite: true,
              })
              gsap.set(item.querySelectorAll(".before"), {
                scaleX: 0,
                x: "-150%",
                overwrite: true,
              })
            }
          })
        },
      })
    )
  })

  onUnmounted(() => {
    if (tl.value) tl.value.kill()
    triggers.forEach((item) => item.kill)
  })

  return {
    tl,
  }
}
