<template>
  <RouterView ref="view" class="project" />
</template>

<script>
import { ref, onMounted } from "vue"
import ScrollTrigger from "gsap/ScrollTrigger"
export default {
  name: "ViewProject",
  setup() {
    const view = ref(null)
    onMounted(() => {
      const imgs = view.value.$el.querySelectorAll("img")
      ;[...imgs].forEach((img) => {
        img.onload = function () {
          ScrollTrigger.refresh()
        }
      })
    })

    return {
      view,
    }
  },
}
</script>

<style lang="scss" scoped>
.project {
  &:deep() {
    .half {
      height: 50vh;
      position: relative;
    }
    .project-image {
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
      opacity: 0;
      display: none;
    }

    .main-title {
      box-sizing: border-box;

      height: 50vh;
      max-width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      font-family: "Mulish";
      font-variation-settings: "wght" 200;

      .project-title-first {
        display: flex;
        align-items: start;
        gap: 5vw;

        small {
          display: flex;
          flex-direction: column;
          gap: 0.2em;
          font-size: 1.1vw;

          .small-line {
            line-height: 100%;
            overflow: hidden;
            white-space: nowrap;

            &:nth-child(1) {
              padding-left: 32px;
            }
          }
        }
      }

      @include md-only {
        .line {
          display: inline-block;
        }
      }
    }

    @include lg-only {
      height: auto;
    }

    .specifications {
      margin: 0 var(--gutter-md) var(--gutter-md);
      gap: var(--gutter-xs);
      display: flex;
      justify-content: space-between;
      @include md-only {
        flex-direction: column;
      }
      .specification {
        display: flex;
        flex-direction: column;
      }
      h3 {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
      }
      span {
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
      }
    }
    .lipsum {
      padding: var(--gutter-md);
      font-size: 32px;
      overflow: hidden;
      box-sizing: border-box;
    }
    .description {
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      max-width: 640px;
      margin: var(--gutter-xs) auto;
      padding: 0 var(--gutter-md);
    }
    .separator-line {
      height: 2px;
      margin: var(--gutter-md) var(--gutter-xl);
      background: var(--primary-800);
    }
    .marquee {
      color: var(--primary-800);
      position: relative;
      overflow: hidden;
      --offset: 20vw;
      --move-initial: calc(-25% + var(--offset));
      --move-final: calc(-50% + var(--offset));
    }

    .marquee__inner {
      width: fit-content;
      display: flex;
      position: relative;
      transform: translate3d(var(--move-initial), 0, 0);
      animation: marquee 5s linear infinite;
      animation-play-state: running;
    }
    .reverse {
      .marquee__inner {
        animation-direction: reverse;
      }
    }

    .marquee span {
      font-size: 128px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      padding: 0 32px;
      white-space: nowrap;
      @include md-only {
        font-size: 64px;
      }
    }

    .fullscreen {
      margin: var(--gutter-md) auto;
      overflow: hidden;
      img {
        width: 100%;
        display: block;
        height: auto;
      }
    }
    .context {
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      max-width: 640px;
      margin: calc(var(--gutter-md) + var(--gutter-xs)) auto;
      padding: 0px var(--gutter-md);
      h3 {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%; /* 22px */
        letter-spacing: 0.25px;
      }
    }
    .dyptique {
      margin: var(--gutter-md);
      display: flex;
      justify-content: center;
      gap: var(--gutter-md);

      @include md-only {
        flex-direction: column;
      }
      img {
        width: 100%;
        border-radius: 2px;
      }
    }
    .carousel {
      background-color: rgba(253, 253, 253, 1);
      color: rgba(34, 34, 34, 1);
      padding: var(--gutter-md);
      .description {
        margin-bottom: var(--gutter-md);
      }
    }
    .slides {
      display: flex;
      .slide {
        width: 22.75vw;
        img {
          width: 100%;
          display: block;
          height: auto;
        }
      }
    }
    .max-width {
      margin: var(--gutter-md);
      img {
        width: 100%;
        height: auto;
        display: block;
        border-radius: 2px;
      }
    }
  }
}

@keyframes marquee {
  0% {
    transform: translate3d(var(--move-initial), 0, 0);
  }

  100% {
    transform: translate3d(var(--move-final), 0, 0);
  }
}
</style>
